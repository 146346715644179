<template>
  <picture :class="auto ? '' : 'product-image'">
    <template v-if="template">
      <source
        v-for="format in IMAGE_FORMATS"
        :key="format.ext"
        :type="format.mime"
        :srcset="getSrcsetForFormat(format.ext)"
      />
      <img :src="fallback" :alt="alt" /> </template
    ><img v-else src="/images/missing.png" :alt="alt" />
  </picture>
</template>

<script>
import { IMAGE_SIZES, IMAGE_FALLBACK_FORMAT, IMAGE_FORMATS } from "@/commerce";

IMAGE_SIZES;
export default {
  name: "ProductImage",
  data() {
    return {
      IMAGE_FORMATS,
    };
  },
  props: {
    template: {
      type: String,
    },
    alt: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      required: false,
    },
    auto: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    src(size, format) {
      return this.template
        .replace("{size}", `${size}w`)
        .replace("{ext}", format);
    },
    getImagesForFormat(format) {
      if (this.size)
        return [
          this.template
            .replace("{size}", `${this.size}w`)
            .replace("{ext}", format),
        ];
      return IMAGE_SIZES.map((size) =>
        this.template.replace("{size}", `${size}w`).replace("{ext}", format)
      );
    },
    getSrcsetForFormat(format) {
      if (this.size) return `${this.src(this.size, format)} ${this.size}w`;
      return IMAGE_SIZES.map(
        (size) => `${this.src(size, format)} ${size}w`
      ).join(", ");
    },
  },
  computed: {
    fallback() {
      const size = this.size ? this.size : IMAGE_SIZES.slice(-1)[0];
      return this.src(size, IMAGE_FALLBACK_FORMAT);
    },
  },
};
</script>
<style lang="scss">
picture.product-image,
picture.product-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}
</style>
