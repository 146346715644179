<template>
  <h1 class="text-blue-grey8 text-h3">Kontakt a kde nás najdete</h1>
  <div class="row col-12 q-px-xl">
    <div class="col-lg-6 col-12 q-pa-md">
      <q-form
        @submit="onSubmit"
        id="contact-form"
        method="POST"
        action="https://formspree.io/f/xoqodrkw"
      >
        <q-input
          color="blue-grey8"
          v-model="name"
          label="Jméno a Příjmení"
          class="q-pa-md bg-accent"
          outlined
          clearable
          ref="nameRef"
          :rules="[(val) => !!val || 'Name is required']"
        ></q-input>
        <q-input
          color="blue-grey8"
          accent
          v-model="phone"
          label="Telefon"
          class="q-pa-md bg-accent"
          outlined
          clearable
        ></q-input>
        <q-input
          color="blue-grey8"
          accent
          v-model="email"
          label="Email"
          class="q-pa-md bg-accent"
          type="email"
          outlined
          clearable
          ref="emailRef"
          :rules="[(value) => value.includes('@') || 'Enter valid Email']"
        ></q-input>
        <q-input
          color="blue-grey8"
          accent
          v-model="message"
          label="Zpráva"
          class="q-pa-md bg-accent"
          outlined
          clearable
          type="textarea"
          ref="messageRef"
          :rules="[(val) => !!val || 'Message is required']"
        ></q-input>
        <span
          class="row justify-evenly q-ma-auto text-caption text-primary full-width"
        >
          kliknutím na tlačítko odeslat souhlasíte se zpracováním osobních údajů
        </span>
        <q-btn
          class="q-pa-md q-ma-md text-blue-grey8"
          icon="fas fa-paper-plane"
          type="submit"
          label="Odeslat"
        />
        <span class="row justify-around text-primary" id="form-status"></span>
      </q-form>
    </div>

    <div class="col-lg-6 col-12 q-pa-md">
      <div class="q-mx-auto col-12">
        <div class="row justify-evenly">
          <div class="text-h6 text-blue-grey8 col-12">TAAVIs TEA s.r.o.</div>
          <p class="text-overline text-blue-grey8 col-12">
            mobil: +420 605 85 32 73
          </p>
          <p class="text-overline text-blue-grey-8 col-12">
            email: info@taavistea.com
          </p>
          <div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2577.5057969816353!2d13.375206577015962!3d49.757741971468185!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470af1f32233b9d7%3A0x4784cd21cc98e607!2zUG9kIFbFoWVtaSBzdmF0w71taSAyNzA0LzExNSwgMzAxIDAwIFBsemXFiCAxLVNldmVybsOtIFDFmWVkbcSbc3TDrQ!5e0!3m2!1scs!2scz!4v1708340121103!5m2!1scs!2scz"
              width="555"
              height="333"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@media (min-width: 888px) {
  .expand-map {
    height: 222px;
    width: 333px;
  }
}
</style>

<script>
export default {
  name: "ContactUs",
  data() {
    return {
      name: "",
      phone: "",
      message: "",
      email: "",
    };
  },
  methods: {
    onSubmit(event) {
      var form = document.getElementById("contact-form");
      var status = document.getElementById("form-status");
      var data = new FormData(event.target);
      fetch(event.target.action, {
        method: form.method,
        body: data,
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            status.innerHTML = "Thanks for your submission!";
            form.reset();
          } else {
            response.json().then((data) => {
              if (Object.hasOwn(data, "errors")) {
                status.innerHTML = data["errors"]
                  .map((error) => error["message"])
                  .join(", ");
              } else {
                status.innerHTML =
                  "Oops! There was a problem submitting your form";
              }
            });
          }
        })
        .catch(() => {
          status.innerHTML = "Oops! There was a problem submitting your form";
        });
    },
  },
};
</script>
