<template>
  <div style="margin-inline: auto" id="search-box" class="revert-overflow">
    <q-input
      filled
      v-model="searchTerm"
      label="Hledání"
      dense
      color="white"
      dark
      label-color="white"
      @focus="focusResults"
      @update:model-value="focusResults"
    >
      <template v-slot:append>
        <q-icon name="search" />
      </template>
    </q-input>
    <div
      :class="`search-results--backdrop ${showResults ? 'show' : 'hide'}`"
      @click="backdropClick"
    >
      <div class="search-results shadow-2">
        <q-input
          input-class="search-field-inner"
          ref="searchFieldInner"
          filled
          clearable
          v-model="searchTerm"
          label="Hledání"
          @clear="showResults = false"
        />
        <div
          v-for="result in searchResults"
          :key="result.id"
          class="search-results--result"
          @click="
            $router.push(`/product/${result.id}`);
            showResults = false;
          "
        >
          <div class="search-results--image">
            <product-image
              :template="result.images[0]"
              :alt="result.name"
              :size="100"
            />
          </div>
          <div class="info">
            <h3 class="text-subtitle1">{{ result.name }}</h3>
            <p class="clamp-3">{{ result.shortDescription }}</p>
          </div>
          <div class="text-caption text-black search-results--price">
            {{ formatPrice(result.regularPrice) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { store, searchProducts } from "@/catalog";
import { formatPrice } from "../commerce";
import ProductImage from "./ProductImage.vue";
import * as $clamp from "clamp-js";

export default {
  name: "SearchInput",
  components: { ProductImage },
  data() {
    return {
      searchTerm: null,
      products: store.state.products,
      showResults: false,
      innerSearchField: null,
      formatPrice,
    };
  },
  methods: {
    focusResults() {
      if (!this.innerSearchField || !this.searchTerm?.length) return;
      this.innerSearchField.focus();
      this.showResults = true;
    },
    backdropClick(event) {
      if (event.target === event.currentTarget) this.showResults = false;
    },
  },
  mounted() {
    this.innerSearchField = document.querySelector(".search-field-inner");
  },
  computed: {
    /**
     * @returns {import('../commerce').Product[]} Search results
     */

    searchResults() {
      const clamp3 = document.querySelectorAll(".clamp-3");
      clamp3.forEach((element) => $clamp(element, { clamp: 3 }));
      if (this.searchTerm?.length < 2) return [];
      return searchProducts(this.searchTerm);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-results {
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: rgba(255, 255, 255, 0.9);
  width: clamp(300px, 620px, 100vw);
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 1;
  transform: translate(-50%, -50%);
  max-height: clamp(440px, 440px, 80vh);

  &--backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;

    &.show {
      display: block;
    }
  }

  &--result {
    display: flex;
    flex-direction: row;
    height: 6rem;
    cursor: pointer;

    .search-results--image {
      width: 6rem;
      height: 6rem;

      picture,
      picture img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      color: black;
      white-space: break-spaces;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      & > * {
        padding: 0 0.5rem;
        margin: 0;
        text-align: left;
      }

      p {
        font-size: 0.8rem;
      }
    }
  }

  &--price {
    margin: auto 0;
    padding-right: 0.5rem;
  }
}
#search-box:focus .search-results,
#search-box:focus-within .search-results {
  display: flex;
}

.revert-overflow .no-wrap {
  overflow: unset !important;
}
</style>
