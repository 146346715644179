<template>
  <div class="row">
    <div class="col-12">
      <h1 class="text-h3">Nevyřízené objednávky</h1>
    </div>
    <div class="col-12 q-px-md">
      <table class="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Vytvořeno</th>
            <th style="text-align: center">Zákazník</th>
            <th>Adresa</th>

            <th>Částka</th>
            <th>Doprava</th>
            <th style="text-align: center">Obsah</th>
            <th>Zaplaceno</th>
            <th>Vyřízeno / Odesláno</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="order in orders" :key="order.orderId">
            <td>{{ order.orderId }}</td>
            <td>
              {{ new Date(order.orderId * 1000).toLocaleString("cs-CZ") }}
            </td>
            <td>
              <q-expansion-item
                dense
                expand-separator
                icon="perm_identity"
                :label="`${order.cart.address.lastName}, ${order.cart.address.firstName}`"
              >
                <a :href="`mailto:${order.cart.address.email}`">{{
                  order.cart.address.email
                }}</a
                ><br /><a :href="`tel:${order.cart.address.phone}`">{{
                  order.cart.address.phone
                }}</a>
              </q-expansion-item>
            </td>
            <td>{{ order.cart.address.city }}</td>
            <td>
              {{ formatPrice(getOrderTotalPrice(order)) }}
            </td>
            <td>
              {{ order.cart.deliveryOption.label
              }}<q-btn
                icon="print"
                flat
                dense
                @click="popupLabel(order.orderId)"
              />
            </td>
            <td>
              <q-expansion-item
                icon="inventory2"
                :label="countOrderItems(order)"
              >
                <p
                  v-for="item in order.cart.items"
                  :key="item.product.id"
                  class="q-ma-none"
                  style="display: flex; flex-direction: row"
                >
                  <span>{{ item.amount }}</span
                  >&times;<router-link
                    class="no-style"
                    :to="`/product/${item.product.id}`"
                  >
                    {{ item.product.name }} </router-link
                  ><span style="text-align: right; margin-left: auto">{{
                    formatPrice(item.product.regularPrice)
                  }}</span>
                </p>
              </q-expansion-item>
            </td>
            <td>
              <span v-if="!order.paid"
                ><span class="text-red q-mx-sm">ne</span
                ><q-btn
                  dense
                  size="sm"
                  @click="showConfirmationDialogPaid[order.orderId] = true"
                  >Označit</q-btn
                ><admin-order-confirmation-dialog
                  v-if="showConfirmationDialogPaid[order.orderId]"
                  v-model="showConfirmationDialogPaid[order.orderId]"
                  :order-id="order.orderId"
                  :order-total="getOrderTotalPrice(order)"
                  kind="pay"
                  @close="if ($event) markOrderAsPaid(order.orderId);"
              /></span>
              <span v-else class="text-green">Ano</span>
            </td>
            <td>
              <span v-if="!order.settled">
                <span class="text-red q-mx-sm">ne</span
                ><q-btn
                  v-if="order.paid"
                  dense
                  size="sm"
                  @click="showConfirmationDialogSettled[order.orderId] = true"
                  >Označit</q-btn
                >
                <admin-order-confirmation-dialog
                  v-if="showConfirmationDialogSettled[order.orderId]"
                  v-model="showConfirmationDialogSettled[order.orderId]"
                  kind="settle"
                  :order-id="order.orderId"
                  :order-total="getOrderTotalPrice(order)"
                  @close="if ($event) markOrderAsSettled(order.orderId);"
                />
              </span>
              <span v-else class="test-green">Ano</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  listUnsettledOrders,
  markOrderAsPaid,
  markOrderAsSettled,
} from "@/firebase";
import { formatPrice, getOrderTotalPrice } from "@/commerce";
import AdminOrderConfirmationDialog from "@/components/AdminOrderConfirmationDialog.vue";

export default {
  name: "AdminOrdersView",
  data() {
    return {
      orders: [],
      showConfirmationDialogPaid: {},
      showConfirmationDialogSettled: {},
      formatPrice,
      getOrderTotalPrice,
    };
  },
  methods: {
    countOrderItems(order) {
      const itemCount = order.cart.items.reduce(
        (acc, item) => acc + item.amount,
        0
      );
      return `${itemCount} ${this.grammatize(itemCount)}`;
    },
    grammatize(count) {
      if (count === 1) {
        return "položka";
      }
      if (count > 1 && count < 5) {
        return "položky";
      }
      return "položek";
    },
    async markOrderAsPaid(orderId) {
      await markOrderAsPaid(orderId);
      this.orders = await listUnsettledOrders();
    },
    async markOrderAsSettled(orderId) {
      await markOrderAsSettled(orderId);
      this.orders = await listUnsettledOrders();
    },
    popupLabel(orderId) {
      const order = this.orders.find((order) => order.orderId === orderId);
      if (!order) return;
      const address = order.cart.address;
      const name = `${address.firstName} ${address.lastName}`;
      // TODO: We're not collecting ZIP code
      window.open(
        `${window.location.origin}/label.html#${name}|${address.street}|${address.city}|${address.city}`,
        "_blank",
        "popup=true,width=800,height=500"
      );
    },
  },
  async mounted() {
    this.orders = await listUnsettledOrders();
  },
  components: {
    AdminOrderConfirmationDialog,
  },
};
</script>

<style lang="scss">
.q-expansion-item__container {
  .q-expansion-item__content.relative-position {
    position: absolute;
    max-height: 440px;
    overflow-y: scroll;
    background: rgb(222, 222, 222);
    width: 100%;
    z-index: 1;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.3);
    padding: 0.5rem;
  }
}
</style>

<style lang="scss" scoped>
a.no-style {
  text-decoration: none;
}

table.table {
  text-align: left;
  font-size: 14px;
  width: 100%;
  padding: 0.5rem;
  th,
  td {
    padding: 0.5rem;
    text-align: left;
  }
}
</style>
