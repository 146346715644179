<template>
  <div class="map-container">
    <div id="map"></div>
    <div class="overlay">
      <p v-for="tid in groups" :key="tid">
        {{ groupRanges[tid - 1] }}&nbsp;<span
          class="color"
          :style="`background-color: ${groupColors[tid - 1]}`"
          >&nbsp;</span
        >
      </p>
    </div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import * as L from "leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import "@maptiler/leaflet-maptilersdk";
import { formatNumber } from "@/commerce";

const PLZEN_COORDS = [49.7477415, 13.3775249];

// eslint-disable-next-line no-unused-vars
const MAP_TILES = {
  default: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
};

const isString = (what) => typeof what === "string" || what instanceof String;

export default {
  name: "LeafletMap",
  props: {
    center: {
      type: [Array, String],
      default: PLZEN_COORDS,
    },
    data: {
      type: Array,
      required: true,
    },
    initialZoom: {
      type: Number,
      default: 7,
    },
    topColor: {
      type: Array,
      default: () => [255, 127, 14],
    },
    bottomColor: {
      type: Array,
      default: () => [31, 119, 180],
    },
    groups: {
      type: Number,
      default: 10,
      description: "The number of groups to split the quantization into.",
    },
  },
  data() {
    return {
      map: null,
      geoProvider: new OpenStreetMapProvider(),
      cityLayers: [],
    };
  },
  watch: {
    data() {
      this.recreate();
    },
  },
  async mounted() {
    const center = await this.geocodeAsNeeded(this.center);
    this.map = L.map("map");
    this.map.setView(center, this.initialZoom);
    // L.tileLayer(MAP_TILES.de, {
    //   maxZoom: 19,
    //   attribution:
    //     "&copy; <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a> | &copy; <a href='https://www.slpy.com'>Slpy</a>",
    // }).addTo(this.map);

    new L.MaptilerLayer({
      apiKey: "jeJyhCyfDPkuvNx82TTm",
      style: L.MaptilerStyle.STREETS.PASTEL,
    }).addTo(this.map);

    this.recreate();
  },
  computed: {
    values() {
      return this.data.map((line) => Number(line.value));
    },
    min() {
      return Math.min(...this.values);
    },
    max() {
      return Math.max(...this.values);
    },
    groupThresholds() {
      const distance = this.max - this.min;
      const step = distance / (this.groups || 1);
      const thresholds = [];

      for (let i = 0; i < this.groups; i++) {
        if (i === this.groups - 1) thresholds.push(this.max);
        else thresholds.push(this.min + step * i);
      }
      return thresholds;
    },
    groupRanges() {
      const ranges = [];
      for (let i = 0; i < this.groups; i++) {
        let range;
        if (i === this.groups - 1)
          range = `> ${Math.floor(this.groupThresholds[i])}`;
        else
          range = `${Math.ceil(this.groupThresholds[i])} - ${Math.floor(
            this.groupThresholds[i + 1]
          )}`;

        ranges.push(range);
      }
      return ranges;
    },
    groupColors() {
      const colors = [];
      const rStep = (this.topColor[0] - this.bottomColor[0]) / this.groups;
      const gStep = (this.topColor[1] - this.bottomColor[1]) / this.groups;
      const bStep = (this.topColor[2] - this.bottomColor[2]) / this.groups;
      for (let i = 0; i < this.groups; i++) {
        colors.push(
          "#" +
            Math.floor(this.bottomColor[0] + i * rStep)
              .toString(16)
              .padStart(2, "0") +
            Math.floor(this.bottomColor[1] + i * gStep)
              .toString(16)
              .padStart(2, "0") +
            Math.floor(this.bottomColor[2] + i * bStep)
              .toString(16)
              .padStart(2, "0")
        );
      }
      return colors;
    },
  },
  methods: {
    valueToGroup(/** @type {Number} */ value) {
      if (value < this.min)
        return 0; // this should never happen, better safe than sorry, though
      else if (value > this.max) return this.groups - 1; // ditto
      for (let i = 0; i < this.groups; i++) {
        if (i == this.groups - 1) return i;
        if (
          value >= this.groupThresholds[i] &&
          value < this.groupThresholds[i + 1]
        )
          return i;
      }
    },
    valueToSize(/** @type {Number} */ value) {
      // we'll make it like '{group+1} km radius'
      return (this.valueToGroup(value) + 1) * 2000;
    },
    valueToColor(/** @type {Number} */ value) {
      const group = this.valueToGroup(value);
      return this.groupColors[group];
    },
    async recreate() {
      this.clear();
      this.data.forEach(async (cityData) => {
        await this.addCity(cityData.city, cityData.value);
      });
    },
    clear() {
      // remove all city layers
      while (this.cityLayers.length) {
        const layer = this.cityLayers.pop();
        layer.remove();
      }
    },
    async addCity(cityName, value) {
      const color = this.valueToColor(value);
      const size = this.valueToSize(value);
      //   console.log({ cityName, value, color, size });
      const coords = await this.findFirst(cityName);
      if (coords === null) {
        console.warn(
          `Found nothing for city ${cityName}. It won't be visible on the map.`
        );
        return;
      }

      const rect = L.circle([coords.y, coords.x], {
        radius: size,
        fillColor: color,
        fillOpacity: 0.4,
        stroke: true,
        color,
        weight: 1,
      })
        .addTo(this.map)
        .bindPopup(`Data: ${formatNumber(value)}`);
      this.cityLayers.push(rect);
    },
    async geocodeAsNeeded(placeNameOrCoords) {
      if (!isString(placeNameOrCoords)) return placeNameOrCoords; // TODO: let's just hope it's already coords for now
      const places = await this.geoProvider.search({
        query: placeNameOrCoords,
      });
      if (places.length === 0) return null;
      return [places[0].y, places[0].x];
    },
    async findFirst(placeName) {
      if (!isString(placeName)) return null;
      const places = await this.geoProvider.search({ query: placeName });
      if (places.length === 0) return null;
      return places[0];
    },
  },
};
</script>

<style lang="scss">
#map {
  height: 480px;
}

.map-container {
  position: relative;

  .overlay {
    z-index: 1000;
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: rgba(255, 255, 255, 0.9);
    color: black;
    font-size: 12px;

    p {
      margin: 0;
      padding: 0;
      padding-left: 0.5em;
      text-align: left;
      width: 100%;
      display: flex;
      flex-direction: row;

      span.color {
        display: inline-block;
        width: 2rem;
        margin-left: auto;
      }
    }
  }
}
</style>
