<template>
  <q-layout view="hHh ppp fff" class="bg-accent" @scroll="scrolled = true">
    <q-header elevated class="bg-blue-grey-8 justify-evenly">
      <q-toolbar class="row">
        <OurHeader />
      </q-toolbar>
    </q-header>
    <q-page-container id="top">
      <router-view />
    </q-page-container>

    <q-footer elevated class="bg-blue-grey-10">
      <Footer />
    </q-footer>
    <conditional-cart
      v-if="!['Cart', 'Address', 'Payment'].includes($route.name)"
    />
  </q-layout>
</template>

<script>
import ConditionalCart from "./components/ConditionalCart.vue";
import Footer from "./components/Footer.vue";
import OurHeader from "./components/OurHeader.vue";

export default {
  name: "App",
  components: {
    Footer,
    OurHeader,
    ConditionalCart,
  },

  data() {
    return {
      scrolled: false,
    };
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Oxanium&display=swap");
#app {
  font-family: Oxanium;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 22px;
}
.back-to-top {
  position: absolute;
  bottom: 0;
  right: 0;
}
img {
  display: block !important;
  object-fit: contain;
  max-width: 333px;
  max-height: 666px;
  width: auto;
  height: auto;
}
</style>
