<template>
  <div class="row">
    <div class="col-12">
      <h1 class="text-h3">Shrnutí objednávek</h1>
    </div>
    <k-p-i
      label="Nezaplacené"
      :value="formatNumber(totalUnpaidOrders)"
      :sub-value="formatPrice(totalUnpaidOrdersPrice)"
      positive-class="bg-red"
    />

    <k-p-i
      label="Zaplacené, nevyřízené"
      :value="formatNumber(totalPaidUnsettledOrders)"
      :sub-value="formatPrice(totalPaidUnsettledOrdersPrice)"
      positive-class="bg-orange"
    />

    <k-p-i
      label="Vyřízené"
      :value="formatNumber(totalPaidSettledOrders)"
      :sub-value="formatPrice(totalPaidSettledOrdersPrice)"
      positive-class="bg-green"
      zero-class="bg-grey"
    />

    <k-p-i
      label="Objednávky celkem"
      :value="formatNumber(totalOrders)"
      :sub-value="formatPrice(totalOrdersPrice)"
      positive-class="bg-grey"
    />
  </div>

  <div class="row">
    <div class="col-12 q-pa-md q-my-lg">
      <q-select
        :options="ordersBarChartDisplayByOptions"
        flat
        dense
        label="Zobrazit podle..."
        stack-label
        v-model="ordersBarChartDisplayBy"
      ></q-select>
    </div>
    <div v-if="orders.length" class="col-12 col-md-6 q-mt-md q-px-md">
      <bar-chart
        :data="ordersBarChartData"
        :groups="ordersBarChartGroups"
        :categories="ordersBarChartXAxis"
        :y-label="ordersBarChartDisplayBy.label"
        title="Objednávky podle dopravy"
        :stepsize="null"
      />
    </div>
    <div class="col-12 col-md-6 q-mt-md q-px-md">
      <bar-chart
        :data="topSoldProductsChartData"
        no-tooltip
        horizontal
        :bar-padding="12"
        :padding-left="16"
        title="Nejprodávanější produkty"
      />
    </div>
  </div>
  <div class="row q-mt-xl">
    <div class="col-12">
      <leaflet-map
        center="Czech republic"
        :groups="4"
        :data="salesByCityChartData"
      />
    </div>
  </div>
</template>

<script>
import {
  formatPrice,
  formatNumber,
  getOrderTotalPrice,
  deliveryOptions,
} from "@/commerce";
import { getProductById } from "@/catalog";
import { listOrders } from "@/firebase";
import BarChart from "@/components/analytics/BarChart.vue";
import KPI from "@/components/analytics/KPI.vue";
import LeafletMap from "@/components/analytics/LeafletMap.vue";
const ordersBarChartDisplayByOptions = [
  { label: "Počet", value: "count" },
  { label: "Cena", value: "price" },
];

const DISPLAY_TOP_N = 7;

export default {
  name: "AdminDashboardView",
  data() {
    return {
      orders: [],
      formatPrice,
      formatNumber,
      getOrderTotalPrice,
      ordersBarChartDisplayBy: ordersBarChartDisplayByOptions[0],
      ordersBarChartDisplayByOptions,
    };
  },
  methods: {
    groupOrdersByMonth() {
      return this.orders.reduce((acc, order) => {
        const createdDate = new Date(order.orderId * 1000);
        const month = `${
          createdDate.getMonth() + 1
        }/${createdDate.getFullYear()}`;
        if (!Object.hasOwnProperty.call(acc, month)) {
          acc[month] = Object.fromEntries(
            deliveryOptions.map((option) => [
              option.label,
              { count: 0, price: 0 },
            ])
          );
        }
        acc[month][order.cart.deliveryOption.label].count++;
        acc[month][order.cart.deliveryOption.label].price +=
          getOrderTotalPrice(order);
        return acc;
      }, {});
    },
  },

  async mounted() {
    this.orders = await listOrders();
  },
  computed: {
    totalOrders() {
      return this.orders.length;
    },
    totalPaidSettledOrders() {
      return this.orders.filter((order) => order.paid && order.settled).length;
    },
    totalPaidUnsettledOrders() {
      return this.orders.filter((order) => order.paid && !order.settled).length;
    },
    totalUnpaidOrders() {
      return this.orders.filter((order) => !order.paid).length;
    },
    totalOrdersPrice() {
      return this.orders.reduce(
        (acc, order) => acc + getOrderTotalPrice(order),
        0
      );
    },
    totalPaidSettledOrdersPrice() {
      return this.orders
        .filter((order) => order.paid && order.settled)
        .reduce((acc, order) => acc + getOrderTotalPrice(order), 0);
    },
    totalPaidUnsettledOrdersPrice() {
      return this.orders
        .filter((order) => order.paid && !order.settled)
        .reduce((acc, order) => acc + getOrderTotalPrice(order), 0);
    },
    totalUnpaidOrdersPrice() {
      return this.orders
        .filter((order) => !order.paid)
        .reduce((acc, order) => acc + getOrderTotalPrice(order), 0);
    },
    ordersBarChartXAxis() {
      return Object.keys(this.groupOrdersByMonth()).sort((a, b) => {
        const [aMonth, aYear] = a.split("/");
        const [bMonth, bYear] = b.split("/");
        return new Date(aYear, aMonth - 1) - new Date(bYear, bMonth - 1);
      });
    },
    ordersBarChartData() {
      const transposed = Object.fromEntries(
        deliveryOptions.map((option) => [option.label, []])
      );
      this.ordersBarChartXAxis.forEach((month) => {
        const data = this.groupOrdersByMonth()[month];
        Object.keys(data).forEach((option) => {
          transposed[option].push(
            data[option][this.ordersBarChartDisplayBy.value]
          );
        });
      });
      return Object.keys(transposed).map((option) => [
        option,
        ...transposed[option],
      ]);
    },
    ordersBarChartGroups() {
      return [deliveryOptions.map((option) => option.label)];
    },
    topSoldProductsChartData() {
      /** @type {Object.<string, {price: number, count: number}>[]} */ const grouped =
        this.orders.reduce((acc, order) => {
          order.cart.items.forEach((item) => {
            if (!Object.hasOwn.call(acc, item.product.id))
              acc[item.product.id] = { price: 0, count: 0 };
            acc[item.product.id].count += item.amount;
            acc[item.product.id].price +=
              item.amount * item.product.regularPrice;
          });
          return acc;
        }, {});
      const sortedProducts = Object.keys(grouped).sort((a, b) => {
        return (
          grouped[b][this.ordersBarChartDisplayBy.value] -
          grouped[a][this.ordersBarChartDisplayBy.value]
        );
      });
      const topNProductIds = sortedProducts.slice(0, DISPLAY_TOP_N);
      // TODO: This might be better, but we would have to tweak the data.categories
      // property of the chart and I don't want to spend more time with this.
      // const chartData = [
      //   new Array(DISPLAY_TOP_N + 1),
      //   new Array(DISPLAY_TOP_N + 1),
      // ];
      // chartData[0][0] = "product";
      // chartData[1][0] = "sales";

      // topNProductIds.forEach((productId, i) => {
      //   chartData[0][i + 1] = getProductById(productId).name;
      //   chartData[1][i + 1] =
      //     grouped[productId][this.ordersBarChartDisplayBy.value];
      // });
      // return chartData;
      return topNProductIds.map((productId) => [
        getProductById(productId).name,
        grouped[productId][this.ordersBarChartDisplayBy.value],
      ]);
    },
    salesByCityChartData() {
      const cities = Array.from(
        new Set(this.orders.map((order) => order.cart.address.city))
      );
      const salesByCity = this.orders.reduce((acc, order) => {
        const city = order.cart.address.city;
        const orderPrice = getOrderTotalPrice(order);
        acc[city].count += 1;
        acc[city].price += orderPrice;
        return acc;
      }, Object.fromEntries(cities.map((city) => [city, { count: 0, price: 0 }])));
      return Object.keys(salesByCity).map((city) => ({
        city,
        value: salesByCity[city][this.ordersBarChartDisplayBy.value],
      }));
    },
  },
  components: {
    BarChart,
    KPI,
    LeafletMap,
  },
};
</script>
