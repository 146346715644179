<template>
  <q-dialog v-model="value">
    <q-card>
      <q-card-section
        ><div class="text-h6">
          Označit jako {{ kind === "pay" ? "zaplacenou" : "vyřízenou" }}?
        </div></q-card-section
      >
      <q-card-section
        ><p>
          Označit objednávku <code>{{ orderId }}</code> v částce
          <code>{{ formatPrice(orderTotal) }}</code> jako
          {{
            kind === "pay"
              ? "zaplacenou? Uživatel obdrží e-mail s potvrzením a fakturou."
              : "vyřízenou? Objednávka se přestane zobrazovat v přehledu nevyřízených."
          }}
        </p></q-card-section
      >
      <q-card-actions align="right">
        <q-btn flat color="secondary" label="Ne" @click="close(false)" />
        <q-btn flat color="primary" label="Ano" @click="close(true)" />
      </q-card-actions> </q-card
  ></q-dialog>
</template>

<script>
import { formatPrice } from "@/commerce";

formatPrice;
export default {
  name: "AdminOrderConfirmationDialog",
  data() {
    return {
      formatPrice,
    };
  },
  emits: ["close", "update:modelValue"],
  methods: {
    close(result) {
      this.$emit("close", result);
      this.value = false;
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
  },
  props: {
    orderId: String,
    orderTotal: Number,
    modelValue: Boolean,
    kind: String,
  },
};
</script>
