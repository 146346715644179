<template>
  <div class="row q-mb-lg">
    <div class="col-1 col-md-2 gt-xs"></div>
    <div class="col-12 col-sm-10 col-md-8">
      <h1 class="text-h3">Dodací a fakturační údaje</h1>
      <div class="row" style="gap: 1rem 0">
        <q-input
          class="col-12 col-sm-6"
          outlined
          dense
          standout
          name="name"
          label="Jméno"
          type="string"
          ref="name"
          :debounce="300"
          :rules="[validateNonempty]"
          v-model="store.state.cart.address.firstName"
          @update:model-value="store.state.cart.save()"
        />
        <q-input
          class="col-12 col-sm-6"
          outlined
          dense
          name="last_name"
          label="Příjmení"
          type="string"
          ref="lastName"
          :debounce="300"
          :rules="[validateNonempty]"
          v-model="store.state.cart.address.lastName"
          @update:model-value="store.state.cart.save()"
        />
        <q-input
          class="col-12 col-sm-8"
          outlined
          dense
          name="street_address"
          label="Ulice a číslo popisné"
          type="string"
          ref="streetAddress"
          :debounce="300"
          :rules="[validateNonempty]"
          v-model="store.state.cart.address.street"
          @update:model-value="store.state.cart.save()"
        />
        <q-input
          class="col-12 col-sm-4"
          outlined
          dense
          name="city"
          label="Město"
          type="string"
          ref="city"
          :debounce="300"
          :rules="[validateNonempty]"
          v-model="store.state.cart.address.city"
          @update:model-value="store.state.cart.save()"
        />
        <q-input
          class="col-12 col-sm-6"
          outlined
          dense
          name="email"
          label="E-mail"
          type="email"
          ref="email"
          :rules="[validateEmail]"
          v-model="store.state.cart.address.email"
          @update:model-value="store.state.cart.save()"
        />
        <q-input
          class="col-12 col-sm-6"
          outlined
          dense
          name="tel"
          label="Telefonní číslo"
          type="string"
          ref="tel"
          :rules="[validatePhoneNumber]"
          v-model="store.state.cart.address.phone"
          @update:model-value="store.state.cart.save()"
        />
        <div class="col-12 text-left" ref="termsAgreement">
          <q-checkbox size="xs" dense v-model="termsAgreement"
            ><span class="text-red-10">&ast;</span>&nbsp;Souhlasím s
            <router-link to="/terms">
              obchodními podmínkami
            </router-link></q-checkbox
          >
        </div>
        <div class="col-12 text-left" ref="gdprAgreement">
          <q-checkbox size="xs" dense v-model="gdprAgreement"
            ><span class="text-red-10">&ast;</span>&nbsp;Souhlasím se
            <router-link to="/gdpr"
              >zpracováním osobních údajů</router-link
            ></q-checkbox
          >
        </div>
      </div>
      <div class="row">
        <div class="col-6 q-mt-md text-left">
          <q-btn label="Zpět" color="secondary" icon="arrow_left" to="/cart" />
        </div>
        <div class="col-6 q-mt-md text-right">
          <q-btn
            label="Odeslat objednávku"
            color="primary"
            icon-right="fas fa-paper-plane"
            @click="checkAndMove"
          />
          <p class="text-caption">s povinností platby</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "@/catalog";

export default {
  name: "OrderAddressView",
  data() {
    return {
      store,
      gdprAgreement: false,
      termsAgreement: false,
    };
  },
  methods: {
    validateEmail(value) {
      const email = value;
      const re = /\S+@\S+\.\S+/;
      return re.test(email) || "E-mail není ve správném formátu";
    },
    validatePhoneNumber(/** @type {String} */ value) {
      const phone = value.split(" ").join("");
      const re = /^((\+|(00))((420)|(421)))?\d{9}$/;
      return re.test(phone) || "Telefonní číslo není ve správném formátu";
    },
    validateNonempty(value) {
      return value.length > 0 || "Toto pole nesmí být prázdné";
    },
    checkAndMove() {
      if (this.gdprAgreement && this.termsAgreement) {
        const elements = [
          this.$refs.name,
          this.$refs.lastName,
          this.$refs.streetAddress,
          this.$refs.city,
          this.$refs.email,
          this.$refs.tel,
        ];
        elements.forEach((el) => {
          el.validate();
        });
        if (elements.every((el) => el.hasError === false))
          this.$router.push("/payment");
      } else {
        if (!this.gdprAgreement) {
          this.$refs.gdprAgreement.classList.add("shake");
          setTimeout(() => {
            this.$refs.gdprAgreement.classList.remove("shake");
          }, 500);
        }

        if (!this.termsAgreement) {
          this.$refs.termsAgreement.classList.add("shake");
          setTimeout(() => {
            this.$refs.termsAgreement.classList.remove("shake");
          }, 500);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.shake {
  animation: shake 0.5s ease-in-out;
}
</style>
