<template>
  <div class="row q-mb-lg">
    <div class="col-1 gt-sm"></div>
    <div class="col-12 col-md-10">
      <h1 class="text-h3">Košík</h1>
      <div v-if="store.state.cart.items.length === 0">
        <p>Košík je prázdný</p>
        <q-btn label="Do obchodu" to="/" />
      </div>
      <template v-else>
        <div
          class="row"
          style="gap: 1rem 0; align-items: center; margin-bottom: 1rem"
          v-for="item in store.state.cart.items"
          :key="item.product.id"
        >
          <div class="col-1 q-px-sm gt-xs cart--product-image">
            <router-link :to="`/product/${item.product.id}`">
              <product-image
                :template="item.product.images[0]"
                :size="100"
                :alt="item.product.name"
              />
            </router-link>
          </div>
          <div class="col-6 text-left no-wrap">
            <router-link
              :to="`/product/${item.product.id}`"
              style="text-decoration: none; color: unset"
            >
              {{ item.product.name }}
            </router-link>
          </div>
          <div class="col-2 text-right">
            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
              "
            >
              <span class="text-caption q-ml-sm gt-xs"
                >{{
                  formatPrice(item.product.regularPrice)
                }}&nbsp;&times;&nbsp;</span
              >

              <q-input
                type="number"
                :debounce="300"
                :model-value="item.amount"
                @update:model-value="item.amount = $event"
                @blur="store.state.cart.setAmount(item.product, item.amount)"
                dense
                style="min-width: 5rem; max-width: 5rem"
                outlined
              />
            </div>
          </div>
          <div class="col-2 text-subtitle1 text-right">
            {{ formatPrice(item.product.regularPrice * item.amount) }}
            <p class="text-caption">
              {{
                formatPrice(
                  Math.round((item.product.regularPrice / 112) * 100) *
                    item.amount
                )
              }}
              bez DPH
            </p>
          </div>
          <div class="col-1 text-right">
            <q-btn
              icon="close"
              flat
              title="Odstranit z košíku"
              @click="store.state.cart.removeProduct(item.product)"
            />
          </div>
        </div>
        <hr />
        <div class="row q-mb-md" style="align-items: center">
          <div class="col-1"></div>
          <div class="col-3 text-left">Doprava</div>
          <div class="col-5 text-left">
            <q-select
              dense
              outlined
              :options="deliveryOptions"
              v-model="selectedDelivery"
            />
          </div>
          <div class="col-2 text-subtitle1 text-right">
            {{
              selectedDelivery.price
                ? formatPrice(selectedDelivery.price)
                : "zdarma"
            }}
          </div>
        </div>
        <div class="row q-mb-md" style="align-items: center">
          <div class="col-1"></div>
          <div class="col-3 text-left">Platba</div>
          <div class="col-5 text-left text-subtitle1">
            Převodem na účet / QR platba
          </div>
          <div class="col-2 text-subtitle1 text-right">zdarma</div>
        </div>
        <hr />
        <div class="row" style="align-items: center">
          <div class="col-1"></div>
          <div class="col-8 text-left">Celkem</div>
          <div class="col-2 text-right text-subtitle1">
            {{ formatPrice(store.state.cart.getTotal(true)) }}
            <p class="text-caption">
              {{ formatPrice(store.state.cart.getTotalWithoutVAT(true)) }} bez
              DPH
            </p>
          </div>
        </div>
        <div class="col-12 q-mt-md q-px-lg text-right">
          <q-btn
            label="Pokračovat"
            color="primary"
            icon-right="arrow_right"
            to="/address"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { store } from "@/catalog";
import { formatPrice } from "@/commerce";
import ProductImage from "@/components/ProductImage.vue";

const deliveryOptions = [
  { label: "Osobní předání v Plzni", value: "personal", price: 0 },
  { label: "Česká pošta", value: "post", price: 129 },
];

export default {
  name: "CartView",
  data() {
    return {
      store,
      deliveryOptions,
      formatPrice,
    };
  },
  computed: {
    selectedDelivery: {
      get() {
        return this.store.state.cart.deliveryOption;
      },
      set(value) {
        this.store.state.cart.setDeliveryOption(value);
      },
    },
  },
  components: { ProductImage },
};
</script>

<style lang="scss">
.cart--product-image {
  picture.product-image,
  picture.product-image img {
    width: 3rem;
    height: 3rem;
    object-fit: cover;
    margin-left: auto;
  }
}
</style>

<style lang="scss" scoped>
.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
