<template>
  <div ref="chart"></div>
</template>

<script>
import "billboard.js/dist/theme/modern.css";
import { bb, bar } from "billboard.js";

export default {
  name: "BarChart",
  props: {
    data: {
      type: Array,
      required: true,
    },
    groups: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: null,
    },
    yLabel: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: "",
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    barPadding: {
      type: Number,
      default: 16,
    },
    rowOriented: {
      type: Boolean,
      default: false,
    },
    stepsize: {
      type: Number,
      default: null,
    },
    noTooltip: {
      type: Boolean,
      default: false,
    },
    paddingLeft: {
      type: Number,
      default: 0,
    },
    yHide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      /** @type {import('billboard.js').Chart?} */ chart: null,
    };
  },
  watch: {
    data() {
      this.chart.config("axis.y.tick.stepSize", this.yStepSize);
      this.chart.load({
        unload: true,
        columns: this.rowOriented ? null : this.data,
        rows: this.rowOriented ? this.data : null,
        categories: this.categories ? this.categories : null,
      });
    },
  },
  mounted() {
    /** @type {import('billboard.js').ChartOptions} */ const config = {
      title: {
        text: this.title,
      },
      bindto: this.$refs.chart,
      color: {
        pattern: [
          "#1f77b4",
          "#ff7f0e",
          "#2ca02c",
          "#d62728",
          "#9467bd",
          "#8c564b",
          "#e377c2",
          "#7f7f7f",
          "#bcbd22",
          "#17becf",
        ],
      },
      data: {
        columns: this.rowOriented ? null : this.data,
        rows: this.rowOriented ? this.data : null,
        type: bar(),
        groups: this.groups,
      },
      tooltip: {
        show: !this.noTooltip,
      },
      padding: this.paddingLeft
        ? {
            left: this.paddingLeft,
          }
        : null,
      axis: {
        rotated: this.horizontal,
        x: this.categories
          ? {
              type: "category",
              categories: this.categories,
            }
          : { show: false },
        y: {
          label: { text: this.yLabel, position: "outer-middle" },
          tick: {
            stepSize: this.yStepSize,
          },
          show: !this.yHide,
        },
      },
      bar: {
        padding: this.barPadding,
      },
    };
    this.chart = bb.generate(config);
  },
  computed: {
    yStepSize() {
      if (this.stepsize) return this.stepSize;
      const yMax =
        this.data.reduce((acc, /** @type {String[]|Number[]} */ category) => {
          const max = Math.max(...category.slice(1));
          if (max > acc) return max;
          return acc;
        }, 0) * 2;
      if (yMax > 10000) return 1000;
      else if (yMax > 3000) return 500;
      else if (yMax > 1000) return 100;
      else if (yMax > 500) return 50;
      else if (yMax > 100) return 20;
      else if (yMax > 50) return 10;
      else if (yMax > 10) return 2;
      else return 1;
    },
  },
};
</script>

<style lang="scss">
.bb-title {
  font-size: 14px !important;
  font-weight: bold;
}
</style>
