<template>
  <div>
    <div class="q-pa-md row">
      <q-btn flat icon="arrow_left" label="Zpět" @click="$router.go(-1)" />
      <h2 class="text-primary text-h4 col-12">GDPR</h2>
      <div class="q-pa-md q-mx-auto col-lg-8 col-md-10 col-12">
        <h3 class="text-h6 col-12">ZÁSADY OCHRANY OSOBNÍCH ÚDAJŮ</h3>
        <p class="text-left">
          Respektujeme soukromí všech jednotlivců, kteří navštěvují naše
          internetové stránky. Jakékoli informace shromažďované o Vás budou
          využívány k poskytování Vámi požadovaných služeb, k informování o
          nových produktech a službách a také k vylepšování našich zákaznických
          služeb. Vaše data budou spravována v souladu s příslušnými zákonnými
          předpisy Evropské unie a Vašeho státu. Vaše informace nebudou
          předávány třetím stranám, ani jakýmkoli jiným společnostem, s nimiž
          přímo nebo nepřímo spolupracujeme na poskytování služeb ve Váš
          prospěch. Nebude shromažďovat osobní data o Vás pomocí souborů cookie,
          nicméně povolením souborů cookie poskytujete svůj souhlas společnosti
          TAAVI's TEA s.r.o. – koupací sudy s ukládáním souborů cookie do Vašeho
          prohlížeče. Upozorňujeme Vás, že zákaz souborů cookie ovlivní
          pravděpodobně funkčnost internetové stránky www.koupacisudy.cz a Vaše
          zkušenosti při jejich používání. Účelem tohoto prohlášení o ochraně
          osobních údajů je popsat druhy informací, které od Vás shromažďujeme
          při Vaší návštěvě našich internetových stránek, a vysvětlit způsob,
          jakým tyto informace používáme. Je naší povinností vyžádat si od Vás
          souhlas s touto skutečností. Pokud si to nepřejete, nebudeme žel
          prostřednictvím této internetové stránky schopni vyřídit žádné Vaše
          žádosti. Pokud souhlasíte, můžete tak učinit s tím, že budeme používat
          Vaše údaje POUZE ke zpracování Vaší žádosti, nebo můžete dát svolení k
          tomu, že je budeme používat k dalším kontaktům (vždy v souladu s níže
          uvedenými Zásadami ochrany osobních údajů).
        </p>
        <h3 class="text-h6 col-12">OCHRANA DAT</h3>
        <p class="text-left">
          Chráníme data v přísném souladu s nařízením (EU) 2016/679, o ochraně
          fyzických osob v souvislosti se zpracováním osobních údajů („nařízení“
          nebo „GDPR“). Tyto Zásady jsou účinné od 17. 2. 2022. Snažíme se, aby
          uložená osobní data byla přesná, relevantní a v přiměřeném množství.
          Budeme udržovat aktuálnost těchto dat a nebudeme tyto informace
          uchovávat déle, než je nezbytně nutné. Data budeme uchovávat bezpečným
          způsobem, abychom zabránili neautorizovanému přístupu jiných lidí.
          Máte právo vidět, co o Vás uchováváme, a opravit jakékoli nepřesnosti.
          Nebudeme sdílet, ani půjčovat jakékoli identifikovatelné informace o
          Vás třetím stranám bez Vašeho souhlasu. S našimi partnery nebo jiným
          stranami můžeme sdílet všeobecné informace o návštěvnosti našich
          internetových stránek, a to takovým způsobem, aby nikdy nemohli být
          identifikováni jednotliví návštěvníci. Naše internetové stránky je
          možné prohlížet bez poskytování jakýchkoli osobních informací. Nicméně
          bez předání Vašich osobních informací s Vámi nebudeme moci dále
          komunikovat.
        </p>

        <h3 class="text-h6 col-12">JAKÉ OSOBNÍ INFORMACE JSOU SHROMAŽĎOVÁNY</h3>
        <p class="text-left">
          1. Vaše osobní informace sbíráme prostřednictvím kontaktního
          formuláře. Mezi tyto informace může patřit například Vaše jméno,
          adresa, e-mailová adresa a telefonní číslo. Tyto informace používáme k
          tomu, abychom: Vám zpříjemnili Vaši návštěvu Vám zaslali důležité
          informace o produktech a službách Vám příležitostně zasílali e-maily o
          propagačních akcích a službách, o nichž si myslíme, že by Vás mohly
          zajímat Pokud se rozhodnete přijímat od nás informace nebo jiné formy
          informačních sdělení, můžete si zvolit tuto možnost zaškrtnutím
          příslušného políčka v kontaktním formuláři. Prostřednictvím e-mailu
          nám také můžete kdykoliv sdělit, že už od nás nechcete dostávat žádná
          informační sdělení. Pokud nám povolíte používat Vaše osobní informace
          pro výše uvedené účely, můžeme tyto informace občas poskytnout
          společnosti nebo organizaci, kterou pověříme úkolem provádět naším
          jménem e-mailovou komunikaci nebo jinou komunikaci z oblasti přímého
          marketingu. V tomto případě zajistíme, aby třetí strany, které budou
          pracovat s Vašimi informacemi, splňovaly příslušná legislativní
          ustanovení o ochraně osobních údajů. <br />2.Základní osobní
          identifikační údaje a adresní údaje Osobním údajem je jakákoli
          informace, která se vztahuje k fyzické osobě v souvislosti s
          poskytováním služeb a prodejem zboží. Tyto údaje zákazník poskytuje
          uzavřením kupní smlouvy o nákupu zboží a podepsáním dodacího listu a
          kupní smlouvy. Mezi tyto údaje může patřit: titul, jméno a příjmení,
          název obchodní firmy, IČO, DIČ, adresa trvalého pobytu, adresa sídla
          nebo místa podnikání, fakturační adresa, místo dodání, e-mail,
          telefonní číslo. <br />3. Údaje o zakoupeném zboží Abychom Vám mohli
          kdykoliv poskytnout další informace o Vašem zakoupeném výrobku, a
          zpříjemnit tak jeho používání, uchováváme informace o zboží v
          následujícím rozsahu: druh a specifikace poskytovaného zboží, objem
          poskytnutého zboží a jeho cena.
        </p>

        <h3 class="text-h6 col-12">
          OSTATNÍ INFORMACE SHROMAŽĎOVANÉ BĚHEM VAŠÍ NÁVŠTĚVY NA NAŠICH
          INTERNETOVÝCH STRÁNKÁCH
        </h3>
        <p class="text-left">
          Kdykoli budete procházet našimi internetovými stránkami na adrese
          www.koupacisudy.cz, číst stránky, nebo stahovat informace, naše
          počítače automaticky zaznamenávají jisté informace o Vaší návštěvě z
          počítače, který používáte pro přístup k našim internetovým stránkám.
          Tyto informace Vás osobně neidentifikují. Říkají nám jen, kolik
          návštěvníků přichází na naše stránky a jaké typy počítačů používají. S
          těmito informacemi můžeme vylepšovat naše internetové stránky pro
          všechny naše návštěvníky a zvyšovat jejich užitečnost pro Vás.
          Informace zahrnují následující: a) Datum a čas Vašeho vstupu na naše
          stránky. b) Kolik a jaké stránky jste navštívili a jak dlouho jste
          byli na našich internetových stránkách. c) Název Vaší internetové
          domény (to může být Váš poskytovatel připojení k internetu, jako např.
          iol.cz, rurotel.cz nebo Vaše pracoviště) a 'IP' adresa (to je číslo
          přiřazené Vašemu počítači, kdykoli se připojíte k internetu), která
          Vám umožní vstoupit na naše internetové stránky. d) Internetový
          prohlížeč, který používáte (např. Microsoft Explorer nebo Safari) a
          operační systém Vašeho počítače (např. Windows 98). f) Internetová
          stránka, z níž jste byli odkázáni na koupacisudy.cz (např. Seznam.cz,
          pokud jste nás našli ve vyhledávači).
        </p>

        <h3 class="text-h6 col-12">OCHRANA E-MAILOVÝCH ADRES</h3>
        <p class="text-left">
          Vaše e-mailová adresa je využívána pouze pro účely odpovědí na Vaše
          dotazy a požadavky, pokud jste nám neudělili výše podrobně uvedený
          souhlas.
        </p>

        <h3 class="text-h6 col-12">OCHRANA ÚDAJŮ V PŘÍPADĚ PIXELOVÝCH TAGŮ</h3>
        <p class="text-left">
          Můžeme používat "pixelové tagy", což jsou malé grafické soubory, které
          nám umožňují sledovat používání našich internetových stránek. Pixelový
          tag může sbírat informace, jako je IP (Internet Protocol) adresa
          počítače, která stáhla stránku, na níž se tag objevuje; URL (Uniform
          Resource Locator) stránky, na níž se pixelový tag objevuje; čas, kdy
          byla stránka obsahující pixelový tag načtena; typ prohlížeče, který
          přenesl pixelový tag; a identifikační číslo jakéhokoli souboru cookie
          umístěného v minulosti do počítače daným serverem. Při korespondenci s
          Vámi prostřednictvím e-mailu kompatibilního s HTML můžeme používat
          technologii pro detekci formátu, která umožňuje pixelovým tagům
          informovat nás o tom, zda jste obdrželi a otevřeli náš e-mail.
        </p>

        <h3 class="text-h6 col-12">
          ÚČELY, PRÁVNÍ DŮVODY A DOBY ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ
        </h3>
        <p class="text-left">
          Rozsah zpracovávaných údajů závisí na účelu zpracování. Vaše údaje
          budeme uchovávat jen po nezbytně nutnou dobu, nebo po dobu stanovenou
          právními předpisy. Poté jsou osobní údaje vymazány. Základní lhůty pro
          zpracování osobních údajů jsou k dispozici níže. V případě zakoupení
          zboží od TAAVI'S TEA s. r. o. jsme oprávněni zpracovávat nebo
          uchovávat základní osobní, identifikační a kontaktní údaje zákazníka,
          údaje o zboží a údaje z komunikace mezi zákazníkem a společností po
          dobu 2 let ode dne uplynutí záruční doby na zboží. V případě jednání
          mezi námi a potenciálním zákazníkem o nákupu zboží, které nebylo
          završeno uzavřením kupní smlouvy, jsme oprávněni uchovávat Vaše
          poskytnuté osobní údaje po dobu 1 měsíce od příslušného jednání.
          Faktury námi vystavené jsou v souladu s § 35 zákona č. 235/2004 Sb., o
          dani z přidané hodnoty archivovány po dobu 10 let od jejich vystavení.
          Z důvodu nutnosti doložit právní důvod pro vystavení faktur jsou po
          dobu 10 let ode dne ukončení smlouvy archivovány i kupní smlouvy.
        </p>

        <h3 class="text-h6 col-12">VAŠE PRÁVA</h3>
        <p class="text-left">
          Je-li to v daném případě relevantní, máte mnohá práva podle právních
          předpisů na ochranu osobních údajů včetně práva vznést námitku proti
          zpracování, práva nahlížet do osobních údajů, které jsou o vás drženy;
          můžete nás také požádat, abychom učinili jakékoli nezbytné změny pro
          zajištění jejich přesnosti a aktuálnosti; dále můžete omezit rozsah
          osobních údajů, které o Vás zpracováváme; požádat o přenos Vašich
          osobních údajů k jiné organizaci; anebo máte právo na výmaz svých
          osobních údajů, tedy tzv. “být zapomenut”. Pokud, v případě potřeby,
          vyžadujete kopii osobních údajů, které o vás držíme, kontaktujte nás
          prosím e-mailem nebo telefonicky. Dovolujeme si pro tento účel
          stanovit poplatek ve výši 200,- Kč. Dále upozorňujeme i na možnost
          podání případné stížnosti k dozorovému úřadu ochrany osobních údajů.
        </p>

        <h3 class="text-h6 col-12">DALŠÍ OTÁZKY NA TÉMA OCHRANY ÚDAJŮ?</h3>
        <p class="text-left">
          Jako jednotlivec máte právo být informován o tom, jaké informace o Vás
          máme a provádět případné opravy. Máte také právo požádat nás, abychom
          tyto informace nepoužívali, tím, že nedáte svůj souhlas na
          registračním formuláři. Máte-li jakékoli specifické dotazy, na které
          jste v této sekci nenalezli odpověď, nebo chcete-li vědět, jaké
          informace máme o Vás v současnosti uložené, pošlete Vaši žádost na
          následující adresu: etec@koupacisudy.cz
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GDPR",
};
</script>
<style></style>
