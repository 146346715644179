<template>
  <div class="text-accent col-12 row full-width">
    <span class="q-ma-auto" style="margin-block: auto"
      ><router-link style="text-decoration: none; color: unset" to="/">
        TAAVIs TEA
      </router-link></span
    >

    <q-space />
    <q-checkbox
      dark
      v-model="showMenu"
      checked-icon="fas fa-times"
      unchecked-icon="fas fa-bars"
      class="row q-mx-auto q-pa-lg menu-on-small-display"
    />

    <div
      class="justify-end row q-mx-auto q-my-auto q-pr-xl menu-on-display"
      v-show="showMenu == true"
    >
      <q-list
        bordered
        separator
        class="row col-12 no-border"
        style="align-items: center"
      >
        <template v-if="user !== null">
          <header-button
            icon="toc"
            label="Nevyřízené objednávky"
            to="/admin/orders"
            @click="showMenu = false"
          />
          <header-button
            icon="leaderboard"
            label="Přehled"
            to="/admin/dashboard"
            @click="showMenu = false"
          />
        </template>
        <header-button
          icon="store"
          label="Obchod"
          to="/"
          @click="showMenu = false"
        />
        <header-button
          label="O nás"
          icon="info"
          to="/about"
          @click="showMenu = false"
        />
        <header-button
          icon="fas fa-paper-plane"
          label="KONTAKTUJTE NÁS"
          to="/contact-us"
          @click="showMenu = false"
        />
        <search-input />
      </q-list>
    </div>
  </div>
</template>

<script>
import SearchInput from "./SearchInput.vue";
import { auth } from "@/firebase";
import { onAuthStateChanged } from "firebase/auth";
import HeaderButton from "./HeaderButton.vue";

export default {
  name: "OurHeader",
  data() {
    return {
      showMenu: false,
      searchTerm: "",
      user: null,
    };
  },
  mounted() {
    onAuthStateChanged(auth, (user) => {
      if (user) this.user = user;
      else this.user = null;
    });
  },
  components: {
    SearchInput,
    HeaderButton,
  },
};
</script>
<style>
a.q-btn.q-btn--active {
  font-weight: bold;
}

.menu-on-small-display {
  display: block;
}

@media (min-width: 888px) {
  .menu-on-small-display {
    display: none !important;
  }
  .menu-on-display {
    display: block !important;
  }
}
</style>
