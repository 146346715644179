<template>
  <div>
    <div class="row">
      <router-link to="/"
        ><q-btn flat label="zpět" icon="arrow_back" color="secondary"
      /></router-link>
    </div>
    <div class="row q-mt-xl q-px-lg">
      <div
        v-if="product.images.length && selectedImage"
        class="col-12 col-md-4"
      >
        <div class="selected-image">
          <product-image
            :template="selectedImage"
            :size="800"
            :alt="product.name"
            style="cursor: zoom-in; max-width: 100%; max-height: 100%"
            @click="openedImage = selectedImage"
          />
        </div>
        <div class="gallery-strip">
          <product-image
            v-for="image in product.images"
            :key="image"
            :alt="product.name"
            :size="200"
            :template="image"
            :class="selectedImage === image ? 'selected' : ''"
            @click="selectedImage = image"
          />
        </div>
      </div>
      <div class="col-12 col-md-8 text-left q-px-md">
        <h1 class="q-mt-none q-mb-sm">{{ product.name }}</h1>
        <p>{{ product.shortDescription }}</p>
        <p class="price text-h4">{{ priceFormatted }}</p>
        <p>
          <q-btn
            color="primary"
            icon="add_shopping_cart"
            size="lg"
            @click="store.state.cart.addProduct(product)"
            >Přidat do košíku
          </q-btn>
        </p>
        <p>
          <span class="price-without-vat"
            >{{
              formatPrice(Math.round((product.regularPrice / 112) * 100))
            }}
            bez DPH</span
          >
        </p>
        <p v-html="product.description"></p>
      </div>
    </div>
    <div v-if="openedImage" class="lightbox" @click="openedImage = null">
      <product-image :template="openedImage" :alt="product.name" />
      <q-btn
        flat
        padding="4px 4px"
        title="Zavřít"
        icon="close"
        size="lg"
        color="black"
        @click="openedImage = null"
      ></q-btn>
    </div>
  </div>
</template>

<script>
import { store } from "@/catalog";
import { formatPrice } from "@/commerce";
import ProductImage from "@/components/ProductImage.vue";

export default {
  name: "ProductDetailsView",
  data() {
    return {
      selectedImage: null,
      openedImage: null,
      store,
      formatPrice,
    };
  },
  mounted() {
    if (!this.product) this.$router.push("/");
    this.selectedImage = this.product.images.length
      ? this.product.images[0]
      : null;
  },
  computed: {
    product() {
      return store.state.products.find(
        (item) => item.id === this.$route.params.productId
      );
    },
    priceFormatted() {
      return formatPrice(this.product.regularPrice);
    },
  },
  components: { ProductImage },
};
</script>

<style lang="scss">
.gallery-strip {
  picture.product-image,
  picture.product-image img {
    min-width: 6rem;
    height: 6rem;
    object-fit: cover;
  }
}

.lightbox {
  picture.product-image,
  picture.product-image img {
    width: 100%;
    height: 100%;
    max-width: 100vw !important;
    max-height: 100vh !important;
    object-fit: scale-down;
  }
}
</style>

<style lang="scss" scoped>
.lightbox {
  z-index: 3000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: grid;
  place-items: center;

  button {
    position: absolute;
    top: 0;
    right: 0;
  }
}

span.price-without-vat {
  font-size: 0.88rem;
}

.selected-image {
  height: 24rem;
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;

  img {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    object-fit: contain;
  }
}
.gallery-strip {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  overflow-x: scroll;
  picture.product-image,
  picture.product-image > img {
    width: 6rem;
    height: 6rem;
    object-fit: cover;
    cursor: pointer;
    border: 1px solid black;
    filter: grayscale(100%) brightness(40%);
    transition: filter 0.3s;

    &.selected,
    &:hover {
      filter: grayscale(0) brightness(100%);
    }
  }
}
</style>
