import { createStore } from "vuex";
import { getCart } from "./commerce";

const products = require("../data/products_optimized.json").sort(
  (
    /** @type {import('./commerce').Product} */ a,
    /** @type {import('./commerce').Product} */ b
  ) => a.name.localeCompare(b.name)
);
const categories = require("../data/categories.json");

export const store = createStore({
  state() {
    return {
      /** @type {import('./commerce').Product[]} */ products,
      /** @type {Object.<string, string[]>} */ categories,
      cart: getCart(),
    };
  },
});

/**
 *
 * @param {String} query
 * @returns {import("./commerce").Product[]}
 */
export const searchProducts = (query) => {
  if (!query || !query?.length) return [];
  /** @type {Object{product: import('./commerce').Product, rank: Number}} */ const productRanked =
    {};
  query
    .trim()
    .split(" ")
    .forEach((word) => {
      store.state.products
        .filter((product) => product.catalogVisibility && product.regularPrice)
        .forEach((product) => {
          if (
            product.name.toLowerCase().includes(word.toLowerCase()) ||
            product.shortDescription
              .toLowerCase()
              .includes(word.toLowerCase()) ||
            product.description.toLowerCase().includes(word.toLowerCase())
          ) {
            if (productRanked[product.id] === undefined) {
              productRanked[product.id] = 0;
            }
            productRanked[product.id]++;
          }
        });
    });

  const result = Object.keys(productRanked)
    .sort((a, b) => {
      productRanked[b] - productRanked[a];
    })
    .map((id) => store.state.products.find((product) => product.id === id));
  // console.log({ query, productRanked, result });
  return result;
};

export const getProductById = (productId) => {
  return store.state.products.find((product) => product.id === productId);
};
