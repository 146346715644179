<template>
  <div>
    <div class="row q-my-none" style="justify-content: center">
      <h2 class="q-my-sm text-h5">
        <span>{{ selectedCategory || "Zvolte kategorii" }}</span
        ><span v-if="selectedSubcategory"> / {{ selectedSubcategory }}</span>
      </h2>
    </div>
    <div class="row" style="justify-content: center; gap: 1rem 2rem">
      <q-btn
        v-if="selectedCategory"
        :class="`category-button ${selectedCategory ? 'sub' : ''}`"
        icon="arrow_back"
        @click="
          selectedCategory = null;
          selectedSubcategory = null;
          selectCategory(null);
        "
      />
      <q-btn
        v-for="category in categories"
        :key="category"
        :class="`category-button ${selectedCategory ? 'sub' : ''} ${
          selectedSubcategory === category ? 'selected' : ''
        }`"
        :label="category"
        @click="selectCategory(category)"
      />
    </div>
  </div>
</template>

<script>
import { store } from "@/catalog";

export default {
  name: "CategoryTree",
  emits: ["category-selected"],
  data() {
    return {
      store,
      /** @type {String?} */ selectedCategory: null,
      /** @type {String?} */ selectedSubcategory: null,
    };
  },
  methods: {
    selectCategory(category) {
      if (this.selectedCategory === null) {
        this.selectedCategory = category;
      } else {
        this.selectedSubcategory = category;
      }
      this.$emit(
        "category-selected",
        this.selectedCategory,
        this.selectedSubcategory
      );
    },
  },
  computed: {
    categories() {
      if (this.selectedCategory === null)
        return Object.keys(this.store.state.categories).sort((a, b) =>
          a.localeCompare(b)
        );
      return [...this.store.state.categories[this.selectedCategory]].sort(
        (a, b) => a.localeCompare(b)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.category-button {
  width: 8rem;
  padding-block: 1rem;

  &.selected {
    background-color: #f0f0f0;
  }

  &.sub {
    width: 6rem;
    padding-block: 0.5rem;
  }
}
</style>
