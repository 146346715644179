<template>
  <div
    v-if="cartVisible"
    :class="`shadow-up-6 conditional-cart ${minimized ? 'minimized' : ''}`"
  >
    <div class="conditional-cart--inner">
      <p>
        V košíku máte
        {{ store.state.cart.items.length }}
        {{ grammatize(store.state.cart.items.length) }}
      </p>
      <div class="row cart-content">
        <div style="display: flex; flex-direction: row">
          <router-link
            v-for="item in store.state.cart.items.slice(0, maxItems)"
            :key="item.product.id"
            :to="`/product/${item.product.id}`"
            :title="item.product.name"
            class="product-in-cart"
          >
            <div class="cart-image">
              <product-image
                :template="item.product.images[0]"
                :alt="item.product.name"
                :size="100"
              />
              <q-badge
                rounded
                color="secondary"
                v-if="item.amount > 1"
                floating
                style="z-index: 1"
              >
                {{ item.amount }}&times;</q-badge
              >
            </div>
          </router-link>
          <span v-if="store.state.cart.items.length > maxItems">...</span>
        </div>

        <q-btn color="primary" icon="shopping_cart" to="/cart"
          >K pokladně ({{ cartTotalPrice }})</q-btn
        >
      </div>
      <q-btn
        flat
        padding="4px 4px"
        :title="minimized ? 'Rozbalit' : 'Sbalit'"
        :icon="minimized ? 'expand_less' : 'expand_more'"
        class="minimize-cart"
        @click="minimized = !minimized"
      ></q-btn>
    </div>
  </div>
</template>

<script>
import { store } from "@/catalog";
import { formatPrice } from "@/commerce";
import ProductImage from "./ProductImage.vue";

export default {
  data() {
    return {
      store,
      maxItems: 5,
      minimized: false,
    };
  },
  methods: {
    grammatize(count) {
      if (count === 1) {
        return "položku";
      }
      if (count > 1 && count < 5) {
        return "položky";
      }
      return "položek";
    },
  },
  computed: {
    cartVisible() {
      return this.store.state.cart.items.length > 0;
    },
    cartTotalPrice() {
      return formatPrice(this.store.state.cart.getTotal());
    },
  },
  components: { ProductImage },
};
</script>

<style lang="scss" scoped>
.conditional-cart {
  z-index: 2000;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 8rem;
  background: white;
  padding-block: 0.5rem;
  display: block;
  // using translate to make transition easier
  transform: translateY(0);
  transition: transform 0.3s ease-in-out;
  &.minimized {
    // translateY has to be {height - 3rem} (first line of text's size with some padding)
    transform: translateY(5rem);
  }

  &--inner {
    position: relative;
  }
  .minimize-cart {
    position: absolute;
    top: 0;
    right: 0;
  }

  .cart-content {
    justify-content: center;
    gap: 1rem;
  }
}
.product-in-cart {
  position: relative;

  div.cart-image {
    width: 3rem;
    height: 3rem;
  }
}
</style>
