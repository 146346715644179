<template>
  <h1 class="q-mb-none text-h3">O nás</h1>
  <div class="row col-12 justify-evenly">
    <div class="col-lg-5 col-12 q-pa-md">
      <h3 class="text-blue-grey8 text-h6">Poslání Společnosti:</h3>
      <p class="text-dark">
        Firma TAAVIs TEA se zabývá importem, zpracováním a mícháním sypaných
        čajů, vývojem a výrobou příslušenství k jeho zpracování i vychutnání.
        <br />

        Jakékoli dotazy týkající se našeho podnikání jsou vítány prostřednictvím
        našeho kontaktního formuláře
      </p>
    </div>

    <div class="col-lg-5 col-12 q-pa-md">
      <h3 class="text-blue-grey8 text-h6">Mission Statement:</h3>
      <p class="text-dark">
        Company TAAVIs TEA is engaged in the import, processing and mixing of
        loose leaf teas, development and production of accessories for its
        processing and enjoyment <br />

        Any inquiries regarding our business are welcome through out our contact
        form
      </p>
    </div>
  </div>
  <div class="row col-12 justify-evenly">
    <div class="col-lg-5 col-12 q-pa-md">
      <h3 class="text-blue-grey8 text-h6">Naše Možnosti:</h3>
      <p class="text-dark">
        Naše společnost v současné době disponuje několika skladovacími a
        výrobními prostory dohromady přes 600 metrů čtverečních. Zaměřených jak
        na skladování a zpracování čaje tak na výrobu a zpracování potravinářské
        nerezi k nejrůznějšímu využití.
        <br />
      </p>
    </div>

    <div class="col-lg-5 col-12 q-pa-md">
      <h3 class="text-blue-grey8 text-h6">Our Capabilities:</h3>
      <p class="text-dark">
        Our company currently has several storage and production plants totaling
        over 600 square meters. Focused both on the storage and processing of
        tea and on the production and processing of food-grade stainless steel
        for various uses. <br />
      </p>
    </div>
  </div>
</template>

<style></style>

<script>
export default {
  name: "AboutUs",
};
</script>
