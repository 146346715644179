<template>
  <q-btn
    flat
    :class="`q-pa-md q-ma-md q-mx-auto col-sm-auto col-10 ${
      isActive ? 'nav-active' : ''
    }`"
    :icon="icon"
    :label="label"
    :to="to"
  />
</template>

<script>
export default {
  name: "HeaderButton",
  props: {
    icon: String,
    label: String,
    to: String,
  },
  computed: {
    isActive() {
      return this.$route.path === this.to;
    },
  },
};
</script>

<style lang="scss">
a.q-btn.nav-active {
  font-weight: bold;
  color: #c0c0c0;
}
</style>
