import { createRouter, createWebHistory } from "vue-router";

import ContactUs from "@/views/ContactUs.vue";
import AboutUs from "@/views/AboutUs.vue";
import ProductsView from "@/views/ProductsView.vue";
import CartView from "@/views/CartView.vue";
import ProductDetailsView from "@/views/ProductDetailsView.vue";
import OrderAddressView from "@/views/OrderAddressView.vue";
import GDPR from "@/views/GDPR.vue";
import TandC from "@/views/TandC.vue";
import PaymentView from "@/views/PaymentView.vue";
import OrdersView from "@/views/admin/OrdersView.vue";
import LoginView from "@/views/admin/LoginView.vue";
import DashboardView from "@/views/admin/DashboardView.vue";

const routes = [
  {
    path: "/about",
    name: "AboutUs",
    component: AboutUs,
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: ContactUs,
  },
  {
    path: "/",
    name: "Products",
    component: ProductsView,
  },
  {
    path: "/product/:productId",
    name: "Product",
    component: ProductDetailsView,
  },
  { path: "/cart", name: "Cart", component: CartView },
  { path: "/address", name: "Address", component: OrderAddressView },
  { path: "/gdpr", name: "GDPR", component: GDPR },
  { path: "/terms", name: "Terms And Conditions", component: TandC },
  { path: "/payment", name: "Payment", component: PaymentView },
  { path: "/admin/", name: "AdminLogin", component: LoginView },
  { path: "/admin/orders", name: "AdminOrders", component: OrdersView },
  {
    path: "/admin/dashboard",
    name: "AdminDashboard",
    component: DashboardView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from) {
    if (to.hash) {
      return {
        el: to.hash,
        top: 0,
        behavior: "smooth",
      };
    }
    if (to.path === from.path) return { top: 0 };
  },
});

router.beforeEach(async (to) => {
  if (!to.path.startsWith("/admin/") || to.path === "/admin/") return true;
  const { auth } = await require("@/firebase");
  if (auth.currentUser) return true;
  return `/admin?next=${to.path}`;
});

export default router;
