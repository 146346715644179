<template>
  <div v-if="!order" class="row"></div>
  <template v-else>
    <h1 class="text-h3">
      Objednávka <code>#{{ order.orderId }}</code>
    </h1>
    <div class="row">
      <div class="col-12">
        <h2 class="text-h4">Platební údaje</h2>
        <p>Objednávka bude vyřízena po uhrazení níže uvedené částky.</p>
        <div class="row">
          <div class="col-12 col-md-6 q-my-md">
            <img :src="qrCodeUrl" alt="QR Platba" style="margin-inline: auto" />
          </div>
          <div class="col-12 col-md-6 q-my-md">
            <table class="payment">
              <tr>
                <th>Číslo účtu</th>
                <td>{{ ACCOUNT_NUMBER }}/{{ BANK_CODE }}</td>
              </tr>
              <tr>
                <th>Variabilní symbol</th>
                <td>{{ order.orderId }}</td>
              </tr>
              <tr>
                <th>Částka</th>
                <td>{{ formatPrice(order.cart.getTotal(true)) }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row q-mb-lg">
      <div class="col-12"><h2 class="text-h4">Souhrn objednávky</h2></div>
      <div class="col-2 gt-sm"></div>
      <div class="col-12 col-md-8">
        <table class="table text-subtitle1">
          <thead>
            <tr>
              <th>Produkt</th>
              <th>Cena</th>
              <th>Množství</th>
              <th>Celkem</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in order.cart.items" :key="item.product.id">
              <td>{{ item.product.name }}</td>
              <td>{{ formatPrice(item.product.regularPrice) }}</td>
              <td>{{ item.amount }}</td>
              <td>
                {{ formatPrice(item.product.regularPrice * item.amount) }}
              </td>
            </tr>
            <tr>
              <td colspan="3">
                Doprava - {{ order.cart.deliveryOption.label }}
              </td>
              <td>
                {{
                  order.cart.deliveryOption.price
                    ? formatPrice(order.cart.deliveryOption.price)
                    : "zdarma"
                }}
              </td>
            </tr>
            <tr class="total">
              <td colspan="3">Celkem</td>
              <td>{{ formatPrice(order.cart.getTotal(true)) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
</template>

<script>
import { store } from "@/catalog";
import { formatPrice, ACCOUNT_NUMBER, BANK_CODE } from "@/commerce";
import { sendOrder } from "@/firebase";

export default {
  name: "PaymentView",
  data() {
    return {
      store,
      formatPrice,
      order: null,
      qrCodeUrl: null,
      ACCOUNT_NUMBER,
      BANK_CODE,
      sendOrder,
    };
  },
  mounted() {
    if (this.store.state.cart.items?.length === 0) {
      this.$router.push({ name: "Products" });
      return;
    }
    this.order = this.store.state.cart.freeze();
    this.qrCodeUrl = this.order.qrCodeUrl();
    this.sendOrder(this.order);
  },
};
</script>

<style lang="scss" scoped>
table.payment {
  width: 100%;
  th {
    text-align: left;
  }
  td {
    text-align: left;
  }
}

table.table {
  width: 100%;

  td,
  th {
    padding-block: 0.5rem;
  }

  th:first-child,
  td:first-child {
    text-align: left;
  }

  th:nth-child(2),
  td:nth-child(2) {
    text-align: right;
  }

  th:last-child,
  td:last-child {
    text-align: right;
  }
  tr.total td {
    border-top: 1px solid black;
    font-weight: bold;
  }
}
</style>
