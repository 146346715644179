<template>
  <div class="col-12 col-sm-6 col-md-3 q-px-lg">
    <q-card :class="`kpi q-mb-md ${colorClass}`">
      <q-card-section>
        <div :class="`${labelSizeClass} q-mb-md`">{{ label }}</div>
        <div class="text-h4">{{ value }}</div>
        <div class="text-h6 q-mt-md">
          {{ subValue ? subValue : "&nbsp;" }}
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
export default {
  name: "KPI",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    subValue: {
      type: String,
      default: null,
    },
    zeroClass: {
      type: String,
      default: "bg-grey",
    },
    subzeroClass: {
      type: String,
      default: "bg-grey",
    },
    positiveClass: {
      type: String,
      default: "bg-green",
    },
  },
  computed: {
    colorClass() {
      if (this.value < 0) return this.subzeroClass;
      else if (this.value == 0) return this.zeroClass;
      else return this.positiveClass;
    },
    labelSizeClass() {
      return this.label.length < 16 ? "text-h6" : "text-subtitle1 q-m";
    },
  },
};
</script>
