import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import {
  getFirestore,
  connectFirestoreEmulator,
  addDoc,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDWfY76thGH_0nOguVZz2ryoWunNMGycjM",
  authDomain: "taavistea-com.firebaseapp.com",
  projectId: "taavistea-com",
  storageBucket: "taavistea-com.appspot.com",
  messagingSenderId: "933887702405",
  appId: "1:933887702405:web:7b2bc9813200625e4e80ec",
  measurementId: "G-B2XVZSLZC1",
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);
export const auth = getAuth(app);

if (process.env.NODE_ENV === "development") {
  connectFirestoreEmulator(firestore, "localhost", 8080);
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(process.env.VUE_APP_RECAPTCHA_SITE_KEY),
  isTokenAutoRefreshEnabled: true,
});

/**
 *
 * @param {import('./commerce').Order} order
 */
export async function sendOrder(order) {
  await addDoc(collection(firestore, "orders"), {
    ...order.serialize(),
    paid: false,
    settled: false,
  });
}

export async function listOrders() {
  const querySnapshot = await getDocs(collection(firestore, "orders"));
  return querySnapshot.docs.map((doc) => doc.data());
}

export async function listUnpaidOrders() {
  const q = query(collection(firestore, "orders"), where("paid", "!=", true));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => doc.data());
}

export async function listUnsettledOrders() {
  const q = query(
    collection(firestore, "orders"),
    where("settled", "!=", true)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => doc.data());
}

async function getInternalOrderId(orderId) {
  const ordersRef = collection(firestore, "orders");
  const q = query(ordersRef, where("orderId", "==", orderId));
  const querySnapshot = await getDocs(q);
  if (querySnapshot.empty) return null;
  return querySnapshot.docs[0].id;
}

export async function markOrderAsPaid(orderId) {
  const internalId = await getInternalOrderId(orderId);
  const orderRef = doc(firestore, "orders", internalId);
  await updateDoc(orderRef, { paid: true });
}

export async function markOrderAsSettled(orderId) {
  const internalId = await getInternalOrderId(orderId);
  const orderRef = doc(firestore, "orders", internalId);
  await updateDoc(orderRef, { settled: true });
}

// export async function markAllAsUnsettled() {
//   const querySnapshot = await getDocs(collection(firestore, "orders"));
//   querySnapshot.forEach((doc) => {
//     updateDoc(doc.ref, { paid: false, settled: false });
//   });
// }
