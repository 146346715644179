<template>
  <div>
    <div class="q-pa-md row">
      <q-btn flat icon="arrow_left" label="Zpět" @click="$router.go(-1)" />
      <h2 class="text-primary text-h4 col-12">VŠEOBECNÉ OBCHODNÍ PODMÍNKY</h2>
      <div class="q-pa-md q-mx-auto col-lg-8 col-md-10 col-12">
        <h3 class="text-h6 col-12">
          (dále jen „obchodní podmínky“)<br />
          TAAVI's TEA s. r. o.<br />
          IČ: 02970813, DIČ: CZ02970813 , Pod Všemi svatými 2704/115, 301 00
          PLZEŇ<br />
          (dále jen „prodávající“)<br />
        </h3>

        <h3 class="text-h6 col-12">1. Úvodní ustanovení a vymezení pojmů</h3>
        <p class="text-left">
          1.1 Tyto obchodní podmínky upravují vzájemná práva a povinnosti
          prodávajícího a kupujícího na straně druhé (dále jen „kupující“) při
          prodeji výrobků. Kupujícím se rozumí fyzická nebo právnická osoba
          označená v záhlaví kupní smlouvy.
          <br />1.2 Tyto obchodní podmínky tvoří nedílnou součást kupní smlouvy
          a kupující podpisem kupní smlouvy současně stvrzuje, že se s těmito
          obchodními podmínkami seznámil a že bere výslovně na vědomí, že tyto
          obchodní podmínky jsou součástí smluvního ujednání mezi ním a
          prodávajícím. Odchylná ujednání účastníků, pokud mají písemnou formu a
          jsou podepsána oběma stranami, mají přednost před ustanovením těchto
          podmínek. <br />1.3 Prodávající výslovně upozorňuje kupujícího, že
          prodávané výrobky jsou určeny pro obvyklé a běžně časté použití,
          není-li uvedeno v kupní smlouvě jinak. Práva kupujícího z vadného
          plnění nejsou dána, je-li vada způsobena nadměrnou zátěží používáním
          výrobku pro jeho nikoli obvyklé a běžně časté použití. <br />1.4 Je-li
          kupujícím spotřebitel, jak je definováno v bodě 1.7 níže, řídí se
          vztahy neupravené těmito obchodními podmínkami zákonem č. 89/2012 Sb.,
          občanský zákoník ve znění pozdějších předpisů (dále jen „ObčZ“) a
          zákonem č. 634/1992 Sb. o ochraně spotřebitele ve znění pozdějších
          předpisů (dále jen „zákon o ochraně spotřebitele“). <br />1.5 Je-li
          kupující jiný subjekt než spotřebitel, jak je definováno v bodě 1.8
          níže, řídí se vztahy neupravené těmito obchodními podmínkami ObčZ
          (přičemž podle § 2158 odst. 1 ObčZ platí, že se ustanovení § 2158 až §
          2174 ObčZ nepoužijí). <br />1.6 Prodávající je fyzická osoba, která
          při uzavírání a plnění smlouvy jedná v rámci své podnikatelské
          činnosti. <br />1.7 Kupující – spotřebitel je každý kupující,
          nejedná-li se o kupujícího - jiný subjekt, jak je definován v odstavci
          1.8 těchto obchodních podmínek. <br />1.8 Kupující – jiný subjekt je
          takový kupující, který je podnikatelem a při uzavření smlouvy je z
          okolností zřejmé, že se koupě týká jeho podnikatelské činnosti.
          Podnikatelem je přitom ten, kdo samostatně vykonává na vlastní účet a
          odpovědnost výdělečnou činnost živnostenským nebo obdobným způsobem se
          záměrem činit tak soustavně za účelem dosažení zisku. Za podnikatele
          se považuje také každá osoba, která uzavírá smlouvy související s
          vlastní obchodní, výrobní nebo obdobnou činností či při samostatném
          výkonu svého povolání, popřípadě osoba, která jedná jménem nebo na
          účet podnikatele. <br />1.9 Předmětem koupě na základě kupní smlouvy
          je výrobek uvedený v kupní smlouvě (dále též jen „výrobek“).
          Rozhodující jsou údaje o výrobku včetně kupní ceny v okamžiku uzavření
          kupní smlouvy.
        </p>
        <h3 class="text-h6 col-12">
          2. Objednávka, kupní smlouva a dodání výrobku
        </h3>
        <p class="text-left">
          <br />2.1 Smluvní vztah mezi prodávajícím a kupujícím vzniká podpisem
          kupní smlouvy, která je vyhotovena na základě objednávky kupujícího.
          Objednávku kupující provede: <br />a) elektronickou poštou na adrese
          etec@koupacisudy.cz <br />b) webovým formulářem dostupným na webových
          stránkách www.koupacisudy.cz <br />c) osobně <br />d) telefonicky na
          telefonním čísle +420 728 717 961 <br />Na základě objednávky vyhotoví
          prodávající kupujícímu cenovou nabídku, jejíž platnost je 7
          kalendářních dnů ode dne vystavení, není-li uvedeno jinak. Ceny za
          výrobky uváděné v cenové nabídce nezahrnují žádné související služby,
          pokud není výslovně uvedeno jinak. Požadavek na poskytnutí
          souvisejících služeb je třeba výslovně uvést objednávce. Po
          odsouhlasení cenové nabídky zašle prodávající kupujícímu zálohovou
          fakturu. Po obdržení objednávky může prodávající vyhotovit návrh kupní
          smlouvy a zaslat ji kupujícímu k potvrzení. Kupní smlouva se stává
          účinnou dnem zaplacení zálohy. Za kupní smlouvu se považuje i
          kupujícím odsouhlasená cenové nabídka, dojde-li k zaplacení zálohové
          faktury. Za podpis kupní smlouvy se považuje i odsouhlasení cenové
          nabídky. Kupní smlouva (popř. cenová nabídka) musí obsahovat tyto
          základní náležitosti: Identifikaci prodávajícího, identifikaci
          kupujícího, přesnou specifikaci výrobku a jeho množství (eventuálně
          včetně souvisejících služeb) a cenu za výrobky. <br />2.2 Ceníky
          vydávané prodávajícím, jakož i ústní a telefonické informace o cenách
          výrobků jsou informativní, pro prodávajícího nezávazné a kupujícím
          nevymahatelné. Prodávající si vyhrazuje právo až do vyhotovení závazné
          cenové nabídky změnit technické parametry, popř. ceny výrobků.
          Prodávající neodpovídá za chyby vzniklé při tisku obchodních a
          technických materiálů. <br />2.3 Dodací lhůta pro dodávku jednoho
          výrobku činí dle jeho náročnosti a vybavení 2-6 týdnů ode dne
          zaplacení zálohy (viz bod III těchto obchodních podmínek). Prodávající
          je oprávněn dodat výrobek i před lhůtou stanovenou v kupní smlouvě na
          základě oznámení termínu předčasného dodání kupujícímu učiněného
          minimálně 3 dny před předčasným datem dodání. V případě, že je
          dodavatel prodávajícího v prodlení s dodáním některého komponentu
          výrobku tak, že dodací lhůta stanovená v kupní smlouvě bude
          překročena, je prodávající oprávněn jednostranně stanovit náhradní
          dodací lhůtu; o této skutečnosti je prodávající povinen kupujícího
          vyrozumět. Dohodnou-li se smluvní strany po uzavření kupní smlouvy
          písemně na jejich změnách, prodlužuje se přiměřeně dodací lhůta
          původně ve smlouvě stanovená, pokud nebude výslovně písemně stanoveno
          jinak. <br />2.4 Kupující je povinen odebrat výrobek do 5 dnů po
          uplynutí dodací, resp. náhradní dodací lhůty. Kupující je povinen
          převzít výrobek v souladu s kupní smlouvou. K převzetí výrobku je
          kupující oprávněn jen tehdy, uhradí-li předtím celou kupní cenu
          výrobku. V případě, že si kupující během stanovené doby zboží
          neodebere nebo neumožní prodávajícímu zboží dodat, je prodávající
          oprávněn účtovat poplatek za uskladnění ve výši 0,5% kupní ceny za
          každý den uskladnění, jakož i náklady, které prodávajícímu vzniknou s
          uskladněním. <br />2.5 Při převzetí výrobku je kupující povinen
          prokázat se originálem kupní smlouvy. <br />2.6 Výrobky mohou být
          dopravovány prodávajícím. Dopravou na místo se rozumí dodání výrobku
          na adresu kupujícího uvedenou v kupní smlouvě, pakliže je toto místo
          dostupné osobním vozem s přívěsem, kupující je povinen zajistit
          sjízdnou přístupovou cestu, v opačném případě se místem dodání rozumí
          poslední přístupné místo, a tam bude výrobek složen. Složení výrobku
          si zajistí sám kupující a nese za něj plnou odpovědnost. Dopravné činí
          8 Kč/km a účtuje se obousměrně. V případě využití dopravy
          prodávajícího je dokompletace a spuštění do provozu v ceně výrobku.
          Spuštěním do provozu se rozumí ukázka 1. zátopu po napuštění koupacího
          sudu. Osobní odběr - kupující si může zajistit vlastní dopravu, v
          tomto případě si prodávající vymezuje právo účtovat si manipulační
          poplatek 500,--Kč. <br />2.7 V případě své nepřítomnosti na adrese
          uvedené v kupní smlouvě ve smluveném termínu dodání výrobku podle
          předchozího odstavce je kupující povinen zajistit převzetí výrobku
          zplnomocněnou osobou, která předloží originál kupní smlouvy a doklad o
          zaplacení kupní ceny a která výrobek od prodávajícího převezme.
          <br />2.8 Prodávající a kupující považují za podstatné porušení kupní
          smlouvy pouze tyto následující skutečnosti, které zakládají právo
          smluvních stran od kupní smlouvy jednostranně odstoupit : <br />a)
          Kupující je v prodlení se zaplacením kupní ceny delší než 10
          kalendářních dnů ode dne splatnosti kupní ceny. <br />b) Kupující je v
          prodlení s převzetím výrobku delším než 3 kalendářní dny po dohodnuté
          lhůtě dodání. <br />c) Prodávající je v prodlení s dodáním výrobku
          delším než 30 kalendářních dnů. <br />2.9. V případě zásahu vyšší moci
          není prodávající povinen akceptovat jakoukoliv objednávku kupujícího
          nebo splnit jakoukoliv smlouvu uzavřenou s kupujícím a neodpovídá za
          škody takto způsobené. Zásahem vyšší moci se rozumí zejména živelné
          pohromy; válečný stav; mobilizace; povstání; občanské nepokoje;
          výjimečný stav; zákonná omezení dovozu, vývozu, výroby; zákaz převodu
          oběživa; dopravní či přepravní komplikace; stávky; sabotáže; pracovní
          spory; obecný nedostatek surovin nebo pracovní síly; omezení dodávky
          energií; porušení povinností dodavateli a další skutečnosti, které
          prodávající nemohl předvídat, přičemž není povinen prokázat jejich
          vliv na plnění smlouvy. Ke zrušení objednávky v důsledku zpožděné
          dodávky je kupující oprávněn pouze po dohodě a se souhlasem
          prodávajícího.
        </p>
        <h3 class="text-h6 col-12">3. Ceny a platební podmínky</h3>
        <p class="text-left">
          <br />3.1 Cena výrobku je závazně sjednána v kupní smlouvě. Je
          stanovena dle aktuálního ceníku prodávajícího v době uzavření kupní
          smlouvy, není-li dohodnuto jinak (dále jen „kupní cena“). V ceně není
          zahrnuta doprava výrobku (viz bod 2.6. obchodních podmínek). <br />3.2
          Ceny mají charakter cen smluvních a jsou uvedeny včetně DPH
          (prodávající je plátcem DPH). <br />3.3 Kupující je povinen zaplatit
          prodávajícímu kupní cenu řádně a včas. Zálohu ve výši 30% z kupní ceny
          je kupující povinen zaplatit do 3 dnů ode dne podpisu kupní smlouvy a
          až jejím zaplacením nabývá smlouva účinnosti. Doplatek ve výši 70% z
          kupní ceny je kupující povinen zaplatit nejpozději 5 dnů před dodáním
          výrobku, popřípadě v hotovosti při předání výrobku. Výrobek bude
          kupujícímu předán až po úplném zaplacení kupní ceny. <br />3.4.
          Neuhradí-li kupující doplatek kupní ceny včas nebo vůbec nebo jiným
          způsobem zabrání dodání výrobku, je povinen prodávajícímu zaplatit
          smluvní pokutu odpovídající 30 % kupní ceny. Kupující prohlašuje, že
          takto sjednanou smluvní pokutu považuje za přiměřenou. <br />3.5
          Nebezpečí nahodilé zkázy a nahodilého zhoršení výrobku přechází na
          kupujícího předáním výrobku kupujícímu. <br />3.6 Převzetí výrobku je
          kupující povinen potvrdit prodávajícímu na dodacím listu. Spolu s
          výrobkem dodá prodávající kupujícímu i fakturu obsahující náležitosti
          dle příslušných právních předpisů. <br />3.7 Vlastnické právo k
          výrobku přechází na kupujícího až úplným zaplacením kupní ceny a
          splněním veškerých finančních závazků kupujícího vůči prodávajícímu,
          včetně závazků z jiných smluv uzavřených mezi kupujícím a
          prodávajícím. Týž následek má, nepřevezme-li Kupující výrobek, ač mu s
          ním prodávající umožnil nakládat. Bezhotovostní platby jsou považovány
          za splněné teprve připsáním částky na účet prodávajícího s řádnou
          identifikací platby tak, aby bylo možné platbu správně identifikovat.
          <br />3.8 Pro případ fakturace jakýchkoli plnění mezi prodávajícím s
          kupujícím, sjednávají strany splatnost faktur 14 dnů ode dne doručení
          faktury. <br />3.9 Pro případ prodlení s placením peněžitých závazků
          strany sjednávají smluvní pokutu ve výši 0,5% z dlužné částky za každý
          den prodlení. Uplatněním smluvní pokuty není dotčeno právo na náhradu
          škody v plné výši.
        </p>
        <h3 class="text-h6 col-12">
          4. Záruka za jakost při převzetí, práva z vadného plnění a reklamační
          řád
        </h3>
        <p class="text-left">
          <br />4.1 Prodávající odpovídá kupujícímu-spotřebiteli, že věc při
          převzetí nemá vady (záruka za jakost při převzetí dle § 2161 ObčZ).
          <br />4.2 Nemá-li věc vlastnosti stanovené v § 2161 ObčZ, může
          kupující-spotřebitel požadovat i dodání nové věci bez vad, pokud to
          není vzhledem k povaze vady nepřiměřené, ale pokud se vada týká pouze
          součásti věci, může kupující požadovat jen výměnu součásti; není-li to
          možné, může odstoupit od smlouvy. Je-li to však vzhledem k povaze vady
          neúměrné, zejména lze-li vadu odstranit bez zbytečného odkladu, má
          kupující právo na bezplatné odstranění vady. Neodstoupí-li
          kupující-spotřebitel od smlouvy nebo neuplatní-li právo na dodání nové
          věci bez vad, na výměnu její součásti nebo na opravu věci, může
          požadovat přiměřenou slevu. Kupující-spotřebitel má právo na
          přiměřenou slevu i v případě, že mu prodávající nemůže dodat novou věc
          bez vad, vyměnit její součást nebo věc opravit, jakož i v případě, že
          prodávající nezjedná nápravu v přiměřené době nebo že by zjednání
          nápravy kupujícímu-spotřebiteli působilo značné obtíže. <br />4.3
          Projeví-li se vada v průběhu šesti měsíců od převzetí výrobku
          kupujícím-spotřebitelem, má se za to, že věc byla vadná již při
          převzetí. <br />4.4 Práva z vadného plnění kupujícímu nenáleží, jde-li
          o vady výrobku, které byly způsobeny dopravou výrobku prováděnou
          kupujícím, neodbornou manipulací či montáží prováděnými kupujícím nebo
          nesprávným používáním. <br />4.5 Vadou výrobku není obvyklý barevný
          nebo strukturový rozdíl u přírodních materiálů. Vadou nejsou ani
          rozdíly rozměrů v rámci běžné tolerance. Prodávající si vyhrazuje
          právo na drobné technické změny prodávaných výrobků. U výrobků
          prodávaných za nižší cenu neodpovídá prodávající za vadu, pro kterou
          byla sjednána nižší cena. <br />4.6 Kupující je povinen uskutečnit
          prohlídku výrobku za účelem zjištění případných vad co nejdříve po
          přechodu nebezpečí škody na věci, pokud možno okamžitě po dopravení
          výrobku. Jestliže Kupující neprovede prohlídku dodaného výrobku včas a
          s odbornou péči, nemůže uplatnit nároky z vad bez ohledu na to, že
          výrobek měl tyto vady již v době přechodu nebezpečí škody na věci.
          Kupující je povinen zjevné vady dodaného výrobku vyznačit při jeho
          převzetí na dodacím listu. Uplynutím lhůty 10 dnů ode dne dodání
          výrobku je vyloučeno uplatňovat nároky z odpovědnosti za zjevné vady.
          Kupující podpisem dodacího listu stvrzuje, že výrobek převzal v
          objednaném množství, jakosti a kvalitě. <br />4.7 Právo z vadného
          plnění se nevztahuje na opotřebení věci způsobené jejím obvyklým
          užíváním, nevhodným užíváním nebo ošetřením, vlivem povětrnostních
          podmínek, neodbornou manipulací, úmyslným poškozením nebo na vady, na
          které byl kupující upozorněn písemně v kupní smlouvě. <br />4.8
          Kupující-spotřebitel je oprávněn uplatnit právo z vady, která se
          vyskytne u výrobku v době dvaceti čtyř měsíců od převzetí,
          kupující-jiný subjekt v době šesti měsíců od převzetí. <br />4.9 Pro
          kupujícího-jiný subjekt se v případě vadného plnění sjednává odchylně
          od příslušných ustanovení Obč.Z. následující: výskyt opravitelné vady
          či opravitelných vad se považuje vždy za nepodstatné porušení smlouvy
          nikoliv za porušení podstatné, při nepodstatném porušení smlouvy má
          kupující-jiný subjekt právo na odstranění vady anebo přiměřenou slevu
          z kupní ceny, a to podle volby prodávajícího, při podstatném porušení
          smlouvy má kupující-jiný subjekt právo na přiměřenou slevu z kupní
          ceny nebo na odstoupení od smlouvy. <br />4.10 Práva z vadného plnění
          se uplatňují u prodávajícího, a to v místě jeho podnikání Pod Všemi
          svatými 115, 301 00, Plzeň. <br />4.11 Postup reklamace: Reklamace
          musí být kupujícím uplatněna vždy písemně spolu s předložením
          originálu nebo kopie kupní smlouvy či písemně dopisem s přiloženým
          originálem nebo kopií kupní smlouvy. Kupující je povinen zabezpečit
          přístup k výrobku servisnímu technikovi prodávajícího v jím navrženém
          termínu. V případě potřeby odvozu výrobku, je kupující povinen
          zajistit přemístění výrobku na místo běžně dostupné osobním
          automobilem s přívěsem. Pokud tato podmínka nebude splněna, vyhrazuje
          si prodávající právo reklamaci zamítnout. Při neoprávněné reklamaci
          (pokud nebude dána odpovědnost prodávajícího za vady) je kupující
          povinen před zpětným převzetím reklamovaného výrobku zaplatit
          prodávajícímu náklady na dopravu a náklady na posouzení existence vady
          dle aktuálního ceníku. <br />4.12 Prodávající rozhodne o reklamaci
          kupujícího - spotřebitele ihned, ve složitých případech do 3
          pracovních dnů. Do této lhůty se nezapočítává doba, která je zapotřebí
          k odbornému posouzení vady. Reklamace včetně odstranění vady musí být
          vyřízena bez zbytečného odkladu, nejpozději do 30 dnů ode dne
          uplatnění reklamace, pokud se prodávající s kupujícím-spotřebitelem
          nedohodnou na delší lhůtě. Kupující je povinen poskytnout při
          vyřizování reklamace tzv. účinnou součinnost. Zejména umožnit možnost
          prohlídku nebo opravu výrobku v dohodnutém termínu případně připravit
          výrobek k převozu nebo jej po opravě převzít. <br />4.13 S výrobky je
          třeba nakládat v souladu s návodem k použití a dodržovat podmínky
          provozu a údržby. Jakékoli zásahy do výrobku mohou být prováděny pouze
          prodávajícím nebo jím zmocněnou osobou.
        </p>
        <h3 class="text-h6 col-12">5. Autorská práva</h3>
        <p class="text-left">
          Bez výslovného svolení prodávajícího nesmí kupující, ani nikdo jiný za
          jakýmkoli účelem kopírovat či jinak rozmnožovat ceníky, obrázky,
          fotografie, návody, tabulky, technické údaje či jejich části, které
          vydává prodávající nebo toto umožňovat třetím osobám. Z nepřesností či
          chyb ve vydávaných materiálech nebo na webových stránkách
          www.koupacisudy.cz nevyplývá kupujícímu žádný nárok vůči
          prodávajícímu. Kupující si je vědom, že mu koupí produktů, jež jsou v
          obchodní nabídce prodávajícího, nevznikají žádná práva na používání
          registrovaných značek, obchodních názvů, firemních log, aj.
          prodávajícího nebo smluvních partnerů prodávajícího, není-li v
          konkrétním případě zvláštní smlouvou sjednáno jinak.
        </p>
        <h3 class="text-h6 col-12">6. Závěrečná ustanovení</h3>
        <p class="text-left">
          <br />6.1 Veškeré smluvní vztahy se řídí českým právním řádem.
          <br />6.2 Tyto obchodní podmínky nabývají účinnosti dnem jejich
          zveřejnění a jsou v příslušném znění rozhodné pro veškeré objednávky
          učiněné v tento den a později. Prodávající si vyhrazuje právo na změnu
          těchto obchodních podmínek. Nové znění obchodních podmínek bude
          zveřejněno na internetových stránkách prodávajícího www.koupacisudy.cz
          . Dnem jejich zveřejnění se ruší účinnost předchozích obchodních
          podmínek, to však nemá vliv na kupní smlouvy uzavřené podle
          dosavadního znění obchodních podmínek (viz první věta tohoto
          odstavce). <br />6.3 Kupující není oprávněn bez souhlasu prodávajícího
          postoupit svou pohledávku vůči prodávajícímu vyplývající z této
          smlouvy na třetí osobu. <br />6.4 Veškeré písemnosti budou zaslány na
          adresu smluvních stran uvedenou v kupní smlouvě. Za den doručení se
          přitom považuje: <br />a) den přijetí zásilky adresátem, <br />b) den
          odepření přijetí zásilky adresátem, <br />c) den vrácení zásilky jako
          nedoručitelné z důvodu, že adresáta nelze na uvedené adrese nalézt,
          <br />d) desátý den následující po dni, kdy byla zásilka uložena na
          poště, bez ohledu na skutečnost, zda se adresát o uložení dozvěděl.
          <br />6.5.Vztahy a případné spory, které vzniknou na základě smlouvy,
          budou řešeny výhradně podle práva České republiky a budou řešeny
          příslušnými soudy České republiky. <br />Smlouva je uzavírána v českém
          jazyce. Pokud vznikne pro potřebu kupujícího překlad textu, platí, že
          v případě sporu o výklad pojmů platí výklad smlouvy v českém jazyce.
          Tyto podmínky byly zveřejněny a nabyly účinnosti dnem 17.2.2022
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TandC",
};
</script>
<style></style>
