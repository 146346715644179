<template>
  <div>
    <h1 class="q-mb-none text-h3">Obchod</h1>
    <div class="filters q-my-md">
      <category-tree @category-selected="selectCategory" />
    </div>
    <div class="q-mb-md">
      ({{ visibleProducts.length }}
      {{ getGrammaticalNumber(visibleProducts.length) }})
    </div>
    <div class="row" style="gap: 0.5rem; justify-content: center">
      <q-card
        v-for="product in visibleProducts"
        style="width: 18rem; margin: 0.5rem; flex-shrink: 0; flex-grow: 0"
        :key="product.id"
        flat
        bordered
      >
        <div
          style="
            aspect-ratio: 1;
            min-width: 100%;
            object-fit: cover;
            padding: 0.5em;
            cursor: pointer;
          "
        >
          <product-image
            @click="
              $router.push({
                name: 'Product',
                params: { productId: product.id },
              })
            "
            :template="product.images[0]"
            :alt="product.name"
          />
        </div>
        <q-card-section style="padding-block: 0">
          <a class="unstyled" :href="`/product/${product.id}`"
            ><h2
              class="text-subtitle1 clamp-1"
              style="
                margin-top: 0;
                height: 1.5em;
                max-height: 1.5em;
                overflow: hidden;
              "
            >
              {{ product.name }}
            </h2></a
          >
        </q-card-section>
        <q-card-section style="padding-block: 0">
          <a class="unstyled" :href="`/product/${product.id}`">
            <p class="text-body2 clamp-3" style="height: 4em; overflow: hidden">
              {{ product.shortDescription }}
            </p></a
          >
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            flat
            color="secondary"
            icon="add_shopping_cart"
            @click="store.state.cart.addProduct(product)"
            >{{ formatPrice(product.regularPrice) }}</q-btn
          >
        </q-card-actions>
      </q-card>
    </div>
  </div>
</template>
<script>
import { store } from "@/catalog";
import { formatPrice } from "@/commerce";
import CategoryTree from "@/components/CategoryTree.vue";
import ProductImage from "@/components/ProductImage.vue";
import * as $clamp from "clamp-js";

export default {
  data() {
    return {
      selectedCategory: null,
      selectedSubcategory: null,
      store,
      formatPrice,
    };
  },
  methods: {
    selectCategory(category, subcategory) {
      this.selectedCategory = category;
      this.selectedSubcategory = subcategory;
    },
    getGrammaticalNumber(count) {
      if (count === 1) return "produkt";
      if (count > 1 && count < 5) return "produkty";
      return "produktů";
    },
  },
  mounted() {
    const clamp3 = document.querySelectorAll(".clamp-3");
    const clamp1 = document.querySelectorAll(".clamp-1");
    clamp3.forEach((element) => $clamp(element, { clamp: 3 }));
    clamp1.forEach((element) => $clamp(element, { clamp: 1 }));
  },
  computed: {
    visibleProducts() {
      return this.store.state.products.filter(
        (product) =>
          product.catalogVisibility &&
          (this.selectedCategory === null ||
            product.category[0] === this.selectedCategory) &&
          (this.selectedSubcategory === null ||
            product.category[1] === undefined ||
            product.category[1] === this.selectedSubcategory) &&
          product.regularPrice !== null
      );
    },
  },
  components: {
    CategoryTree,
    ProductImage,
  },
};
</script>

<style lang="scss" scoped>
a.unstyled {
  text-decoration: none;
  color: unset;
}
</style>
