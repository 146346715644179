<template>
  <div class="row">
    <div v-if="user === null" class="col-12">
      <div class="row">
        <div class="col-2 gt-xs"></div>
        <div class="col-12 col-md-8">
          <p>Přihlašte se</p>
          <q-input
            v-model="userName"
            outlined
            standout
            dense
            label="E-mail"
            class="q-my-sm"
            :disable="formDisabled"
          />
          <q-input
            v-model="password"
            label="Heslo"
            outlined
            standout
            dense
            class="q-my-sm"
            type="password"
            :disable="formDisabled"
          />
          <p v-if="loginError !== null">{{ loginError }}</p>
          <q-btn @click="signIn" :disable="formDisabled" label="Přihlásit" />
        </div>
      </div>
    </div>
    <div v-else class="col-12">
      <div class="col-12 text-center">Přihlášen jako {{ user.email }}</div>
      <q-btn class="q-mt-lg" @click="signOut">Odhlásit se</q-btn>
    </div>
  </div>
</template>

<script>
import { auth } from "@/firebase";
import {
  onAuthStateChanged,
  reload,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

export default {
  name: "AdminLoginView",
  data() {
    return {
      user: null,
      userName: "",
      password: "",
      loginError: null,
      formDisabled: false,
    };
  },
  methods: {
    navigateNext(fallback = null) {
      if (Object.keys(this.$route.query).includes("next"))
        this.$router.push({ path: this.$route.query.next });
      else if (fallback) this.$router.push(fallback);
    },
    signIn() {
      this.formDisabled = true;
      signInWithEmailAndPassword(auth, this.userName, this.password)
        .then((userCredential) => {
          const user = userCredential.user;
          this.user = user;
          this.loginError = null;
          this.navigateNext({ name: "AdminDashboard" });
        })
        .catch((error) => {
          console.log("Error signing in", error);
          this.loginError = error;
        })
        .finally(() => {
          this.formDisabled = false;
        });
    },
    signOut() {
      signOut(auth)
        .then(() => {
          this.user = null;
        })
        .catch((error) => {
          console.log("Error signing out", error);
          this.loginError = error;
        });
    },
  },
  mounted() {
    if (this.user) {
      reload(this.user)
        .then(() => {
          this.navigateNext();
        })
        .catch((error) => {
          console.error(error);
          this.user = null;
        });
    }
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.user = user;
        this.navigateNext();
      } else {
        this.user = null;
      }
    });
  },
};
</script>
