<template>
  <div class="row full-width">
    <div class="col-md-3 col-12 q-mx-auto"></div>
    <q-separator vertical inset color="secondary" />
    <div class="col-md-3 col-12 q-mx-auto">
      <p class="text-overline">
        TAAVIs TEA s.r.o.<br />
        Pod Všemi svatými 115<br />
        301 00, Plzeň<br />
        IČ: 02970813<br />DIČ: CZ02970813<br />
        Vedená u Krajského soudu v Plzni<br />
      </p>
    </div>
    <q-separator vertical inset color="secondary" />
    <div class="col-md-3 col-12 q-mx-auto"></div>
  </div>
</template>
<style>
.footer-button-size {
  padding-left: 4rem;
}
</style>

<script>
export default {
  name: "OurFooter",
};
</script>
