/**
 * @typedef {Object} Product
 * @property {String} id
 * @property {String} type
 * @property {String} catalogNumber
 * @property {String} name
 * @property {Boolean} published
 * @property {Boolean} isHighlighted
 * @property {Boolean} catalogVisibility
 * @property {String} shortDescription
 * @property {String} description
 * @property {Boolean} inStock
 * @property {Number?} weight
 * @property {Number?} regularPrice
 * @property {String[]} category
 * @property {String[]} tags
 * @property {String[]} images
 * @property {Number} position
 */

/**
 * @typedef {Object} SimplifiedProduct
 * @property {String} id
 * @property {String} name
 * @property {Number} regularPrice
 * @property {String[]} category
 * @property {String[]} tags
 * @property {String} catalogNumber
 * @property {String[]} images
 */

/**
 * @typedef {Object} DeliveryOption
 * @property {String} label
 * @property {String} value
 * @property {Number} price
 */

/**
 * @typedef {Object} ProductInCart
 * @property {SimplifiedProduct} product
 * @property {Number} amount
 */

/**
 * @typedef {Object} Address
 * @property {String} firstName
 * @property {String} lastName
 * @property {String} email
 * @property {String} phone
 * @property {String} street
 * @property {String} city
 */

export const ACCOUNT_NUMBER = "2000959309";
export const BANK_CODE = "2010";

// See optimize_images.py
export const IMAGE_SIZES = [100, 200, 400, 800, 1200, 1800];
export const IMAGE_FORMATS = [
  { ext: ".webp", mime: "image/webp" },
  { ext: ".avif", mime: "image/avif" },
  { ext: ".png", mime: "image/png" },
];
export const IMAGE_FALLBACK_FORMAT = ".png";

const simplfiedKeys = [
  "id",
  "name",
  "regularPrice",
  "category",
  "tags",
  "catalogNumber",
  "images",
];

class Address {
  defaults = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    street: "",
    city: "",
  };
  constructor() {
    Object.assign(this, Address.defaults);
  }

  serialize() {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone,
      street: this.street,
      city: this.city,
    };
  }
}

/**
 *
 * @param {Product} product
 * @returns {SimplifiedProduct}
 */
const productSimplifier = (product) => {
  return simplfiedKeys.reduce((acc, key) => {
    acc[key] = product[key];
    return acc;
  }, {});
};

/**
 * @type {DeliveryOption[]}
 */
export const deliveryOptions = [
  { label: "Osobní předání v Plzni", value: "personal", price: 0 },
  { label: "Česká pošta", value: "post", price: 129 },
];

export class Order {
  /**
   * @param {Cart} cart
   */
  constructor(cart) {
    this.cart = cart.copy();
    this.orderId = Math.floor(Date.now() / 1000).toString();
  }

  qrCodeUrl() {
    return `https://api.paylibo.com/paylibo/generator/czech/image?accountNumber=${ACCOUNT_NUMBER}&bankCode=${BANK_CODE}&vs=${
      this.orderId
    }&message=Objednávka ${
      this.orderId
    } taavistea.com&amount=${this.cart.getTotal(true)}`;
  }

  serialize() {
    return {
      orderId: this.orderId,
      cart: this.cart.serialize(),
    };
  }
}

export class Cart {
  constructor() {
    /**
     * @type {ProductInCart[]}
     */
    this.items = [];

    /**
     * @type {DeliveryOption}
     */
    this.deliveryOption = deliveryOptions[0];

    /**
     * @type {Address}
     */

    this.address = new Address();
  }

  /**
   * @returns {Cart}
   */
  copy() {
    const cart = new Cart();
    cart.items = [...this.items.map((item) => ({ ...item }))];
    cart.deliveryOption = { ...this.deliveryOption };
    cart.address = new Address();
    Object.assign(cart.address, this.address);
    return cart;
  }

  serialize() {
    return {
      items: this.items,
      deliveryOption: this.deliveryOption,
      address: this.address.serialize(),
    };
  }

  /**
   * @param {Product | SimplifiedProduct} product
   * @param {Number} amount
   */
  addProduct(product, amount = 1) {
    const simplifiedProduct = productSimplifier(product);
    if (amount < 1) amount = 1;
    const itemIndex = this._getProductIndex(simplifiedProduct);
    if (itemIndex === -1) {
      this.items.push({ product: simplifiedProduct, amount });
      this.save();
      return;
    }
    this.items[itemIndex].amount += amount;
    this.save();
  }

  /**
   * @param {DeliveryOption} option
   */
  setDeliveryOption(option) {
    this.deliveryOption = option;
    this.save();
  }

  /**
   * @param {String} value
   */
  setDeliveryOptionByValue(value) {
    const option = deliveryOptions.find((opt) => opt.value === value);
    if (option === undefined) return;
    this.setDeliveryOption(option);
  }

  /**
   * Set product's amount to a specific value. If the product is not in the cart, it will be added.
   * If the amount is 0, the product will be removed from the cart.
   * @param {Product | SimplifiedProduct} product
   * @param {Number} amount
   */
  setAmount(product, amount = 1) {
    const simplifiedProduct = productSimplifier(product);
    if (amount < 1) return this.removeProduct(product);
    const itemIndex = this._getProductIndex(product);
    if (itemIndex === -1) {
      this.items.push({ product: simplifiedProduct, amount });
      this.save();
      return;
    }
    this.items[itemIndex].amount = amount;
  }

  /**
   * @param {Product | SimplifiedProduct} product
   * @returns {Number}
   */
  _getProductIndex(product) {
    for (const itemPos in this.items) {
      if (this.items[itemPos].product.id === product.id) return itemPos;
    }
    return -1;
  }

  /**
   * @param {Product | SimplifiedProduct} product
   * @returns {Number}
   */
  getProductAmount(product) {
    const itemIndex = this._getProductIndex(product);
    if (itemIndex === -1) return 0;
    return this.items[itemIndex].amount;
  }

  toJSON() {
    return JSON.stringify({
      items: this.items,
      deliveryOption: this.deliveryOption,
      address: this.address,
    });
  }

  save() {
    localStorage.setItem("com.taavistea.cart", this.toJSON());
  }

  /**
   *
   * @param {String} json
   * @returns {Cart}
   */
  static fromJSON(json) {
    const cart = new Cart();
    const savedData = JSON.parse(json);
    cart.items = savedData.items;
    cart.deliveryOption = savedData.deliveryOption;
    cart.address = new Address();
    cart.address = Object.assign(cart.address, savedData.address);
    return cart;
  }

  /**
   *
   * @param {Product | SimplifiedProduct} product
   */
  removeProduct(product) {
    this.items = this.items.filter((item) => item.product.id !== product.id);
    this.save();
  }
  getItems() {
    return this.items;
  }
  getTotal(includeDelivery = false) {
    const itemsTotal = this.items.reduce(
      (acc, item) => acc + item.product.regularPrice * item.amount,
      0
    );
    return itemsTotal + (includeDelivery ? this.deliveryOption.price : 0);
  }

  getTotalWithoutVAT(includeDelivery = false) {
    const itemsTotal = this.items.reduce(
      (acc, item) =>
        acc + Math.round((item.product.regularPrice / 112) * 100) * item.amount,
      0
    );
    return itemsTotal + (includeDelivery ? this.deliveryOption.price : 0);
  }

  clear() {
    this.items = [];
    this.deliveryOption = deliveryOptions[0];
    this.save();
  }

  /**
   * @returns {Order}
   */
  freeze() {
    const order = new Order(this);
    this.clear();
    return order;
  }
}

/** @type {Cart?} Singleton instance */ var cartInstance = null;

export const getCart = () => {
  if (cartInstance === null) {
    const storage = localStorage.getItem("com.taavistea.cart");
    if (storage !== null) cartInstance = Cart.fromJSON(storage);
    else cartInstance = new Cart();
  }
  return cartInstance;
};

export const formatPrice = (/** @type {Number} */ price) => {
  return price.toLocaleString("cs-CZ", {
    style: "currency",
    currency: "CZK",
    currencyDisplay: "symbol",
    maximumFractionDigits: 0,
  });
};

export const formatNumber = (/** @type {Number} */ number) => {
  return number.toLocaleString("cs-CZ");
};

export const getOrderTotalPrice = (order) => {
  return (
    order.cart.items.reduce(
      (acc, item) => acc + item.product.regularPrice * item.amount,
      0
    ) + order.cart.deliveryOption.price
  );
};
